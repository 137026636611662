import { images, SLAcademyLaunchBlogDescription } from "../../constants"
import { CommentCard } from "../../components";
import './SLAcademyLaunchBlogDetails.css'

import * as React from "react";
import { ReactPhotoCollage } from "react-photo-collage";



const SLAcademyLaunchBlogDetails = ({ id }) => {
    
    const blogDetails = SLAcademyLaunchBlogDescription.find(blog => blog.id === id);

    return (
        <>

            <div className="app_blogdetails_body ">
                <div className="app_blogdetails_header_img">
                    <img src={images.Rectangle_Bacground_img} alt="" className="app_blogdetails_header_img_01" />
                    <img src={images.rectangle_1} alt="" className="app_blogdetails_header_img_02" />

                    <div className="app_blogdetails_hero_img section__padding">
                        <img src={blogDetails?.header_img} />
                    </div>
                </div>
                <div className="app_blogdetails_title section__padding">
                    <p>{blogDetails?.title}</p>
                </div>
                <div className="app_blogdetails_sec_section">
                    <div className="app_blogdetails_sec_section_desc section__padding">
                        <p >{blogDetails?.sec_section.description_p1}</p>
                        <p >{blogDetails?.sec_section.description_p2}</p>
                    </div>
                    <div className="app_blogdetails_sec_section_img section__padding">
                        <img src={blogDetails?.sec_section.image} atl="img " />
                        <img src={images.rectangle_19} className="app_blogdetails_sec_section_bg" />
                        <span className="app_blogdetails_sec_section_img_title">{blogDetails?.sec_section.image_title}</span>
                    </div>
                </div>
                <div className="app_blogdetails_third_section section__padding">
                    <p >{blogDetails?.third_section}</p>
                </div>

                <div className="app_blogdetails_fourth_section section__padding">
                    <p className="p__heading">{blogDetails?.fourth_section.title}</p>
                    <p>{blogDetails?.fourth_section.description_p1}</p>
                    <p>{blogDetails?.fourth_section.description_p2}</p>
                    <p>{blogDetails?.fourth_section.description_p3}</p>
                </div>
                <div className="app_blogdetails_fifth_section">
                    <div className="app_blogdetails_fifth_section_img">
                        <div className="app_blogdetails_fifth_section_img_01">
                            <img src={images.rectangle_17} className="" />
                        </div>
                        <div className="section__padding flex__center">
                            <img src={blogDetails?.fifth_section.image} alt="img" />
                            <p className="app_blogdetails_img_title">{blogDetails?.fifth_section.image_title}</p>
                        </div>
                    </div>

                    <div className="app_blogdetails_fifth_section_desc">
                        <h2 className="section__padding app_blogdetails_fifth_section_desc_title ">{blogDetails?.fifth_section?.title}</h2>
                        <div className="section__padding">
                            <p className="padding_left">{blogDetails?.fifth_section.ul_p}</p>
                            <p>{blogDetails?.fifth_section?.description}</p>
                        </div>

                    </div>
                </div>

                <div className="section__padding app_blogdetails_sixth_section_parent">
                    <p className="app_blogdetails_sixth_section_child">{blogDetails?.sixth_section?.title}</p>
                    <div className="app_blogdetails_sixth_section section__padding">
                        <div className="app_blogdetails_sixth_section_img">
                            <div className="app_blogdetails_sixth_section_01 ">
                                <div className="app_blogdetails_sixth_section_parent">
                                    <img src={blogDetails?.sixth_section?.image_01} />
                                    <p>{blogDetails?.sixth_section?.image_01_title}</p>
                                </div>
                                <div className="app_blogdetails_sixth_section_parent">
                                    <img src={blogDetails?.sixth_section?.image_02} />
                                    <p>{blogDetails?.sixth_section?.image_02_title}</p>
                                </div>
                            </div>
                            <div className="app_blogdetails_sixth_section_02">
                                <img src={blogDetails?.sixth_section?.image_03} />
                            </div>
                            <div className="app_blogdetails_sixth_section_03 ">
                                <div>
                                    <img src={blogDetails?.sixth_section?.image_04} />
                                </div>
                                <div>
                                    <img src={blogDetails?.sixth_section?.image_05} />
                                </div>
                            </div>
                        </div>
                        <div className="app_blogdetails_sixth_section_footer section__padding" >
                            <p className="app_blogdetails_sixth_section_footer_01">{blogDetails?.sixth_section?.first_section_title}</p>

                            <p className="">{blogDetails?.sixth_section?.sec_section_title}</p>

                            <p>{blogDetails?.sixth_section?.third_section_title}</p>

                        </div>
                    </div>

                </div>

                <div className="app_blogdetails_seventh_section section__padding">
                    {Object.entries(blogDetails?.seventh_section).map(([key, value], index) => (
                        <p key={index}>
                            {value}
                        </p>
                    ))}
                </div>
                <div className="app_blogdetails_Photo_Gallery section__padding">
                    <img src={images.rectangle_20} className="app_blogdetails_Photo_Gallery_bg" />
                    <p className="section__padding">Photo Gallery</p>
                    <div className="app_blogdetails_Photo_Gallery_flex app_blogdetails_Photo_Gallery_flex_01">
                        <div className="app_blogdetails_Photo_Gallery_flex_03">
                            <div className="app_blogdetails_Photo_Gallery_flex">
                                <img src={blogDetails?.Photo_Gallery?.image_01} className="flex__center app_blogdetails_Photo_Gallery_01" />
                                <img src={blogDetails?.Photo_Gallery?.image_02} className="app_blogdetails_Photo_Gallery_img_border flex__center" />
                            </div>
                            <div className="app_blogdetails_Photo_Gallery_flex_02">
                                <img src={blogDetails?.Photo_Gallery?.image_04} className="flex__center" />
                            </div>
                        </div>
                        <div className="app_blogdetails_Photo_Gallery_flex_div">
                            <img src={blogDetails?.Photo_Gallery?.image_03} className="flex__center" />
                            <img src={blogDetails?.Photo_Gallery?.image_05} className="flex__center" />
                        </div>
                    </div>
                    <div className="app_blogdetails_Photo_Gallery_flex ">
                        <div className="app_blogdetails_Photo_Gallery_div">
                            <img src={blogDetails?.Photo_Gallery?.image_06} className="app_blogdetails_Photo_Gallery_flex_01 flex__center" />
                        </div>
                        <div className="app_blogdetails_Photo_Gallery_flex_03">
                            <div className="app_blogdetails_Photo_Gallery_flex_04">
                                <img src={blogDetails?.Photo_Gallery?.image_07} className="flex__center" />
                            </div>
                            <div className="app_blogdetails_Photo_Gallery_flex">
                                <img src={blogDetails?.Photo_Gallery?.image_08} className="app_blogdetails_Photo_Gallery_img_border flex__center" />
                                <img src={blogDetails?.Photo_Gallery?.image_09} className="app_blogdetails_Photo_Gallery_img_border flex__center" />
                            </div>
                        </div>
                    </div>
                </div>

                <p className="section__padding app_blogdetails_comments_title">Applauding Comments</p>
                <div className="app_BlogDetails_Comments section__padding">

                    {
                        blogDetails?.Applauding_Comments.map((data, idx) => {
                            return <CommentCard key={"Comment Card " + idx} image={data?.image} name={data?.name} intro={data?.intro} comment={data?.comment} />
                        })
                    }

                </div>
                <div className="app_blogdetails_contact_details section__padding">
                    <p>To know more, please visit: <a href="https://tpaedu.org" target="_blank">www.tpaedu.org</a></p>
                    <p>Contact us at: <a href="mailto:slacademy@tpaedu.org" target="_blank">slacademy@tpaedu.org</a></p>
                    <p>LinkedIn: <a href="https://www.linkedin.com/company/the-platform-academy" target="_blank">www.linkedin.com/company/the-platform-academy</a></p>

                </div>
            </div>

        </>
    )
}

export default SLAcademyLaunchBlogDetails;