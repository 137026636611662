import React from 'react';
import { NavBar, SnaplogicCourses, Footer } from '../container';



const SnaplogicCoursePage = () => {
    return(
        <>
            <NavBar />
            <SnaplogicCourses />
            <Footer />
        </>
    );
};

export default SnaplogicCoursePage;