import React, { useState } from 'react';
import { GlobalContext } from './constants/Context';
import './App.css';
import { BrowserRouter, HashRouter, Routes, Route } from 'react-router-dom';
import { Home, AllCoursesPage, SnaplogicCoursePage, MobiusCoursePage, LaunchEvent, DataEngineeringJobHuntEvent, DataScienceJobHuntEvent, AboutSnapLogic, AboutMobius, SystemIntegratorsInfo, EducationalInstitutesInfo, LearnersInfo, Blogs, SLAcademyLaunchBlog, Dashboard, Construction, Error, Reset_Password, Registration } from "./routes";
import { SnaplogicDeveloperCertification, SnaplogicIntegratorCertification, SnaplogicAdministratorCertification, SnaplogicArchitectCertification, SnaplogicEnterpriseAutomationProfessionalCertification, SnaplogicAccreditationCertification } from './routes';
import ScrollToTop from './constants/ScrollToTop';
import Login_signup from './container/login_signup/Login_signup';
import { Toaster, toast } from 'react-hot-toast';


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [globalState, setGlobalState] = useState(
    {
      loginStatus: sessionStorage.getItem("id") ? true : false,
      userid: "",
      username: "",
      email: "",
      registeredCourses: "",
      contactusPopup: false,
      eventSectionRef: null
    }
  );
  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    sessionStorage.removeItem("id")

    setGlobalState({
      loginStatus: sessionStorage.getItem("id") ? true : false,
      userid: "",
      username: "",
      email: "",
      registeredCourses: "",
      contactusPopup: false
    });
    // sessionStorage.setItem("id", res.data?.entities[0].id)
    toast.success("You have been logged out successfully. Thank you for using our service!")
  }
  const GlobalStateHandler = (updatedState) => {
    setGlobalState((currentState) => {
      return { ...currentState, ...updatedState }
    });
  };

  return (
    <GlobalContext.Provider value={
      {
        global_state: globalState,
        global_action: GlobalStateHandler,
        onLogout:handleLogout,
        onLogin:handleLogin
      }
    } >
      <div className="App">
        <Toaster position='bottom-center' toastOptions={{ className: "toast" }} />
        <HashRouter>
          <ScrollToTop />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/all-courses' element={<AllCoursesPage />} />
            <Route path='/snaplogic-courses' element={<SnaplogicCoursePage />} />
            <Route path='/mobius-courses' element={<MobiusCoursePage />} />
            <Route path='/sledu-series' element={<LaunchEvent />} />
            <Route path='/data-engineering' element={<DataEngineeringJobHuntEvent />} />
            <Route path='/data-science' element={<DataScienceJobHuntEvent />} />
            <Route path='/snaplogic' element={<AboutSnapLogic />} />
            <Route path='/mobius' element={<AboutMobius />} />
            <Route path='/system-integrators' element={<SystemIntegratorsInfo />} />
            <Route path='/educational-institutes' element={<EducationalInstitutesInfo />} />
            <Route path='/learners' element={<LearnersInfo />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/snaplogic-courses/snaplogic-developer-certification' element={<SnaplogicDeveloperCertification />} />
            <Route path='/snaplogic-courses/snaplogic-integrator-certification' element={<SnaplogicIntegratorCertification />} />
            <Route path='/snaplogic-courses/snaplogic-administrator-certification' element={<SnaplogicAdministratorCertification />} />
            <Route path='/snaplogic-courses/snaplogic-architect-certification' element={<SnaplogicArchitectCertification />} />
            <Route path='/snaplogic-courses/snaplogic-enterprise-automation-professional-certification' element={<SnaplogicEnterpriseAutomationProfessionalCertification />} />
            <Route path='/snaplogic-courses/snaplogic-accreditation-certification' element={<SnaplogicAccreditationCertification />} />
            <Route path='/login' element={<Login_signup   />} />
            <Route path='/signup' element={<Login_signup />} />
            <Route path='/resetpassword' element={<Reset_Password/>}/>
            <Route path='/course-registration' element={<Registration/>}/>
            <Route path='/blogs' element={<Blogs />} />

            <Route path='/blogdetails/:id' element={<SLAcademyLaunchBlog/>} />
            <Route path='/under-construction' element={<Construction />} />
            <Route path='/*' element={<Error />} />
          </Routes>
        </HashRouter>
      </div>
    </GlobalContext.Provider>
  );
}

export default App;