import { NavBar, Footer } from '../container';
import { useParams } from 'react-router-dom';
import { SLAcademyLaunchBlogDetails } from '../container';


const SLAcademyLaunchBlog = () => {
    const { id } = useParams();

    return (
        <>
            <NavBar />
            <SLAcademyLaunchBlogDetails id={id} />
            <Footer />
        </>
    );
};

export default SLAcademyLaunchBlog;