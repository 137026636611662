import React from 'react';
import './EventInformation.css';
import { DataEngineeringJobFair } from '../../constants';
import { images } from '../../constants';
import { Link } from 'react-router-dom';



const DataEngineeringEvent = () => {
    return(
        <>
            <div className="app__registration section__padding">

                <div className="app__registration_event flex__center">

                    <div className="app__registration_event-image">
                        <img src={DataEngineeringJobFair.eventImage} alt="Data Engineering Job Fair Event" />
                    </div>

                    <div className="app__registration_event-info-container flex__center">
                        <div className="app__registration_event-info">
                            <h3 className="app__registration_event-info-title p__subheading">
                                {DataEngineeringJobFair.eventTitle}
                            </h3>
                            <div className="app__registration_event-info-eventdetails flex__center">
                                <img src={images.BookmarkIcon} alt="Bookmark Icon" />
                                <p className="p__paragraph">
                                    {DataEngineeringJobFair.eventType}
                                </p>
                            </div>
                            <div className="app__registration_event-info-eventdetails flex__center">
                                <img src={images.CalendarIcon} alt="Calendar Icon" />
                                <p className="p__paragraph">
                                    {DataEngineeringJobFair.eventDate}
                                </p>
                            </div>
                            <div className="app__registration_event-info-eventdetails flex__center">
                                <img src={images.LocationIcon} alt="Location Icon" />
                                <p className="p__paragraph">
                                    {DataEngineeringJobFair.eventLocation}
                                </p>
                            </div>
                            {
                                DataEngineeringJobFair.eventStatus === "Concluded"
                                ?
                                <Link to={DataEngineeringJobFair.navigatingLink} className="app__registration_event-info-concluded">
                                    <button type='button'>Event Concluded</button>
                                </Link>
                                :
                                <Link to={DataEngineeringJobFair.navigatingLink} target="_blank" className="app__registration_event-info-register">
                                    <button type='button'>Register Now</button>
                                </Link>
                            }
                        </div>
                    </div>

                </div>

                <p className="app__registration_about p__subheading">
                    About the event
                </p>
                <p className="app__registration_aboutdescription p__paragraph">
                    {DataEngineeringJobFair.eventDescription}
                </p>

                <div className="app__registration_details flex__center">

                    {
                        DataEngineeringJobFair.eventItinerary &&

                        <div className="app__registration_details-itinerary">
                            <p className='p__subheading'><b>Event Itinerary:</b></p>
                            {
                                DataEngineeringJobFair.eventItinerary.map( (itineraryDetail) => {
                                    return (
                                        <div className='app__registration_details-itinerary-info flex__center'>
                                            <p className='p__paragraph'>{itineraryDetail.time}</p>
                                            <p className='p__paragraph'>{itineraryDetail.details}</p>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    }

                    {
                        DataEngineeringJobFair.eventVenue &&
                        
                        <div className="app__registration_details-venue">
                            <p className='p__subheading'><b>Event Venues:</b></p>
                            {
                                DataEngineeringJobFair.eventVenue.map( (venueDetail) => {
                                    return (
                                        <div className='app__registration_details-venue-info flex__center'>
                                            <p className='p__paragraph'><b>{venueDetail.city}</b></p>
                                            <p className='p__paragraph'>&ndash;</p>
                                            <p className='p__paragraph'>{venueDetail.address}</p>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    }

                </div>

            </div>
        </>
    );
};

export default DataEngineeringEvent;
