import React, { useContext, useState } from 'react';
import './NavBar.css';
import { GlobalContext } from '../../constants/Context';
import { images } from '../../constants/index.js';
import { AllSnaplogicCourses, AllMobiusCourses } from '../../constants/index.js';
// import { BiSearchAlt2 } from 'react-icons/bi';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown, MdClose } from 'react-icons/md';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const NavBar = () => {
    const { global_state: { username, loginStatus, eventSectionRef }, global_action, onLogout } = useContext(GlobalContext);

    const [registrationPopUp, setRegistrationPopUp] = useState(true);

    const location = useLocation();
    const navigate = useNavigate();

    const showLoginBtn = location.pathname != "/login" && location.pathname != "/signup"


    const [navigation, setNavigation] = useState({ courses: false, aboutUs: false, whyUs: false });
    const [subNavigation, setSubNavigation] = useState({ snaplogic: false, mobius: false });

    const ChangeDropDown = (navigationType, value) => {
        setNavigation((previous) => { return { ...previous, [navigationType]: value } });
    }

    const ChangeSubDropDown = (navigationType, value) => {
        setSubNavigation((previous) => { return { ...previous, [navigationType]: value } });
    };
    const handleLogout = () => {
        // Call the handleLogout function from the parent component
        onLogout()
    };

    const [menuOverlay, setMenuOverlay] = useState(false);

    const temp = JSON.parse(sessionStorage.getItem("id"))



    const ScrollIntoEvents = () => {
        if (location.pathname !== "/") {
            navigate("/");

            // setTimeout(() => {
            //     window.scrollTo(
            //         { top: eventSectionRef.current.offsetTop, behavior: 'smooth' }
            //     );
            // }, 3000);
        }

        else
            window.scrollTo(
                { top: eventSectionRef.current.offsetTop, behavior: 'smooth' }
            );
    };

    return (
        <>
            <div className={menuOverlay ? "app__navbar_container navbar__fixed" : "app__navbar_container"}>
                {
                    registrationPopUp && location.pathname === "/" &&

                    <div className="app__registrationpopup">

                        <div className="app__registrationpopup-info flex__center">
                            <p className="p__paragraph">
                                Register for our Certified Training Programs today
                            </p>
                            <Link to="/course-registration" className="app__registrationpopup-info-button" onClick={() => setRegistrationPopUp(false)}>
                                <button className='flex__center' type='button'>Register</button>
                            </Link>
                        </div>

                        <div className="app__registrationpopup-close flex__center" onClick={() => setRegistrationPopUp(false)}>
                            <MdClose />
                        </div>

                    </div>
                }

                <div className="app__navbar" id="navbar">

                    <Link to='/' className="app__navbar_title" onClick={() => window.scrollTo(0, 0)}>
                        <div className="app__navbar_title-logo">
                            <img src={images.ThePlatformAcademyLogo} alt="The Platform Academy Logo" />
                        </div>
                    </Link>

                    {
                        window.innerWidth > 850 ?
                            <div className="app__navbar_navigation flex__center">
                                {/* <div className="app__searchbar">
                                        <input type="text" placeholder='Search'/>
                                        <BiSearchAlt2 className='search__icon' />
                                    </div> */}
                                <div className="app__navbar_navigation-links flex__center">
                                    <Link className={navigation.courses ? "app__navbar_navigation-menu shift__down_courses" : "app__navbar_navigation-menu"} onMouseEnter={() => ChangeDropDown('courses', !navigation.courses)} onMouseLeave={() => { ChangeDropDown('courses', false); ChangeSubDropDown('snaplogic', false); ChangeSubDropDown('mobius', false) }}>
                                        <p className="p__navigation flex__center">
                                            Courses
                                            {
                                                navigation.courses
                                                    ?
                                                    <MdOutlineKeyboardArrowUp className='dropdown_arrow' />
                                                    :
                                                    <MdOutlineKeyboardArrowDown className='dropdown_arrow' />
                                            }
                                        </p>

                                        {
                                            navigation.courses &&

                                            <>
                                                <p className={subNavigation.snaplogic ? "p__navigation_small flex__center selected_subnavigation" : "p__navigation_small flex__center"} onMouseEnter={() => ChangeSubDropDown('snaplogic', true)} onMouseLeave={() => ChangeSubDropDown('snaplogic', false)}>
                                                    <Link to='/snaplogic-courses' className='p__navigation_small'>SnapLogic</Link>
                                                </p>
                                                {
                                                    subNavigation.snaplogic &&

                                                    <div className='app__navbar_subnavigation-menu'>
                                                        {
                                                            AllSnaplogicCourses.map((course) => <p className="p__navigation_small flex__center" onMouseEnter={() => ChangeSubDropDown('snaplogic', true)}><Link to={course.navigatinglink} className='p__navigation_small'>{course.title}</Link></p>)
                                                        }
                                                    </div>
                                                }
                                                <p className={subNavigation.mobius ? "p__navigation_small flex__center selected_subnavigation" : "p__navigation_small flex__center"} onMouseEnter={() => ChangeSubDropDown('mobius', true)} onMouseLeave={() => ChangeSubDropDown('mobius', false)}>
                                                    <Link to='/mobius-courses' className='p__navigation_small'>Mobius</Link>
                                                </p>
                                                {
                                                    subNavigation.mobius &&

                                                    <div className='app__navbar_subnavigation-menu'>
                                                        {
                                                            AllMobiusCourses.map((course) => <p className="p__navigation_small flex__center" onMouseEnter={() => ChangeSubDropDown('mobius', true)}><Link to={course.navigatinglink} className='p__navigation_small'>{course.title}</Link></p>)
                                                        }
                                                    </div>
                                                }
                                            </>
                                        }
                                    </Link>

                                    <Link className={navigation.aboutUs ? "app__navbar_navigation-menu shift__down_aboutUs" : "app__navbar_navigation-menu"} onMouseEnter={() => ChangeDropDown('aboutUs', !navigation.aboutUs)} onMouseLeave={() => ChangeDropDown('aboutUs', false)}>
                                        <p className="p__navigation flex__center">
                                            About Us
                                            {
                                                navigation.aboutUs
                                                    ?
                                                    <MdOutlineKeyboardArrowUp className='dropdown_arrow' />
                                                    :
                                                    <MdOutlineKeyboardArrowDown className='dropdown_arrow' />
                                            }
                                        </p>

                                        {
                                            navigation.aboutUs &&

                                            <>
                                                <p className="p__navigation_small flex__center" onClick={() => ScrollIntoEvents()}>
                                                    <Link to='' className='p__navigation_small'>Events</Link>
                                                </p>
                                                <p className="p__navigation_small flex__center">
                                                    <Link to='' className='p__navigation_small' onClick={() => global_action({ contactusPopup: true })}>Contact Us</Link>
                                                </p>
                                                <p className="p__navigation_small flex__center">
                                                    <Link to='/under-construction' className='p__navigation_small'>In News</Link>
                                                </p>
                                                <p className="p__navigation_small flex__center">
                                                    <Link to='/blogs' className='p__navigation_small'>Blogs</Link>
                                                </p>
                                            </>
                                        }
                                    </Link>
                                    <Link className={navigation.whyUs ? "app__navbar_navigation-menu shift__down_whyUs" : "app__navbar_navigation-menu"} onMouseEnter={() => ChangeDropDown('whyUs', !navigation.whyUs)} onMouseLeave={() => ChangeDropDown('whyUs', false)}>
                                        <p className="p__navigation flex__center">
                                            Why Us
                                            {
                                                navigation.whyUs
                                                    ?
                                                    <MdOutlineKeyboardArrowUp className='dropdown_arrow' />
                                                    :
                                                    <MdOutlineKeyboardArrowDown className='dropdown_arrow' />
                                            }
                                        </p>

                                        {
                                            navigation.whyUs &&

                                            <>
                                                <p className="p__navigation_small flex__center">
                                                    <Link to='/learners' className='p__navigation_small'>For Learners</Link>
                                                </p>
                                                <p className="p__navigation_small flex__center">
                                                    <Link to='/system-integrators' className='p__navigation_small'>For System Integrators</Link>
                                                </p>
                                                <p className="p__navigation_small flex__center">
                                                    <Link to='/educational-institutes' className='p__navigation_small'>For Educational Institutes</Link>
                                                </p>
                                            </>
                                        }
                                    </Link>
                                </div>
                                {
                                    loginStatus ?

                                        <>


                                            <div className='app__userdetails flex__center'>
                                                <Link to='/dashboard' className={location.pathname == "/dashboard" ? "hidden" : ""}>
                                                    <p className='p__navigation_small'>{"Hi, " + temp?.username}</p>
                                                </Link>

                                                <Link to="/" className={location.pathname == "/dashboard" ? "app__navbar_user-menu" : "hidden"}>
                                                    <p className='p__navigation_small p__navigation_user'>
                                                        {"Hi, " + temp?.username}
                                                    </p>
                                                </Link>

                                                <Link to="">
                                                    <p onClick={handleLogout} className="p__navigation_small flex__center">
                                                        Logout
                                                    </p>
                                                </Link>

                                            </div>

                                        </>
                                        :
                                        <div className={location.pathname == "/login" || location.pathname == "/resetpassword" || location.pathname == "/signup" ? "hidden" : ""}>
                                            <Link to='/login' className='app__navbar_navigation-button'>
                                                <button className='nav__button'>Login/Signup</button>
                                            </Link>
                                        </div>
                                }
                            </div>
                            :
                            <div className="app__navbar_navigation-small flex__center" onClick={() => { setMenuOverlay(!menuOverlay); ChangeDropDown('courses', false); ChangeDropDown('whyUs', false); ChangeDropDown('aboutUs', false) }}>
                                {menuOverlay ? <MdClose className='hamburger app__navbar_overlay-close' /> : <GiHamburgerMenu className='hamburger' />}
                            </div>
                    }

                </div>

                <div className={menuOverlay ? "app__navbar_overlay flex__center" : "app__navbar_overlayclose flex__center"}>
                    <div className="app__navbar_overlay-navigation flex__center">
                        <Link className="app__navbar_navigation-menu" onClick={() => { ChangeDropDown('courses', !navigation.courses); ChangeDropDown('aboutUs', false); ChangeDropDown('whyUs', false) }} style={navigation.courses ? { height: 'auto' } : {}} >
                            <p className="p__navigation flex__center">
                                Courses
                                {
                                    navigation.courses
                                        ?
                                        <MdOutlineKeyboardArrowUp className='dropdown_arrow' />
                                        :
                                        <MdOutlineKeyboardArrowDown className='dropdown_arrow' />
                                }
                            </p>
                            {
                                navigation.courses &&

                                <>
                                    <p className="app__navigation_menu-smalldevices flex__center">
                                        <Link to='/snaplogic-courses' className='p__navigation_small'>SnapLogic</Link>
                                    </p>
                                    <p className="app__navigation_menu-smalldevices flex__center">
                                        <Link to='/mobius-courses' className='p__navigation_small'>Mobius</Link>
                                    </p>
                                </>
                            }
                        </Link>
                        <Link className="app__navbar_navigation-menu" onClick={() => { ChangeDropDown('aboutUs', !navigation.aboutUs); ChangeDropDown('courses', false); ChangeDropDown('whyUs', false) }} style={navigation.aboutUs ? { height: 'auto' } : {}} >
                            <p className="p__navigation flex__center">
                                About Us
                                {
                                    navigation.aboutUs
                                        ?
                                        <MdOutlineKeyboardArrowUp className='dropdown_arrow' />
                                        :
                                        <MdOutlineKeyboardArrowDown className='dropdown_arrow' />
                                }
                            </p>
                            {
                                navigation.aboutUs &&

                                <>
                                    <p className="app__navigation_menu-smalldevices flex__center" onClick={() => { ScrollIntoEvents(); setMenuOverlay(false); ChangeDropDown('courses', false); ChangeDropDown('whyUs', false); ChangeDropDown('aboutUs', false) }}>
                                        <Link to='' className='p__navigation_small'>Events</Link>
                                    </p>
                                    <p className="app__navigation_menu-smalldevices flex__center">
                                        <Link to='' className='p__navigation_small' onClick={() => global_action({ contactusPopup: true })}>Contact Us</Link>
                                    </p>
                                    <p className="app__navigation_menu-smalldevices flex__center">
                                        <Link to='/under-construction' className='p__navigation_small'>In News</Link>
                                    </p>
                                    <p className="app__navigation_menu-smalldevices flex__center">
                                        <Link to='/blogs' className='p__navigation_small'>Blogs</Link>
                                    </p>
                                </>
                            }
                        </Link>
                        <Link className="app__navbar_navigation-menu" onClick={() => { ChangeDropDown('whyUs', !navigation.whyUs); ChangeDropDown('courses', false); ChangeDropDown('aboutUs', false) }} style={navigation.whyUs ? { height: 'auto' } : {}} >
                            <p className="p__navigation flex__center">
                                Why Us
                                {
                                    navigation.whyUs
                                        ?
                                        <MdOutlineKeyboardArrowUp className='dropdown_arrow' />
                                        :
                                        <MdOutlineKeyboardArrowDown className='dropdown_arrow' />
                                }
                            </p>
                            {
                                navigation.whyUs &&

                                <>
                                    <p className="app__navigation_menu-smalldevices flex__center">
                                        <Link to='/learners' className='p__navigation_small'>For Learners</Link>
                                    </p>
                                    <p className="app__navigation_menu-smalldevices flex__center">
                                        <Link to='/system-integrators' className='p__navigation_small'>For System Integrators</Link>
                                    </p>
                                    <p className="app__navigation_menu-smalldevices flex__center">
                                        <Link to='/educational-institutes' className='p__navigation_small'>For Educational Institutes</Link>
                                    </p>
                                </>
                            }
                        </Link>
                        {
                            loginStatus ?
                                <>
                                    <Link to='/dashboard' className={false ? "hidden" : "app__navbar_navigation-menu"} onClick={() => setMenuOverlay(false)}>
                                        <p className="p__navigation  flex__center">
                                            Your Profile
                                        </p>
                                    </Link>
                                    {/* <Link to="/" className={location.pathname == "/dashboard" ? "app__navbar_navigation-menu" : "hidden"}>
                                        <p className='p__navigation_small  '>
                                            {"Hi, " + temp?.username}
                                        </p>
                                    </Link> */}
                                    <Link to='' className='app__navbar_navigation-menu' onClick={() => setMenuOverlay(false)}>
                                        <p onClick={handleLogout} className=' p__navigation_small  flex__center'>Logout</p>
                                    </Link>
                                </>
                                :
                                <Link to='/login' className="app__navbar_navigation-menu" onClick={() => setMenuOverlay(false)}>
                                    <p className="p__navigation flex__center">
                                        Login/Signup
                                    </p>
                                </Link>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default NavBar;