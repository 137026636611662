import axios from "axios";
import { useContext, useState } from "react";
import { toast } from "react-hot-toast";
import './ResetPassword.css';
import { GlobalContext } from "../../constants/Context";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import bcrypt from 'bcryptjs';
import emailjs from '@emailjs/browser';

export const ResetPassword = () => {
    const navigate = useNavigate()
    const [step, setState] = useState(1);
    const [email, setemail] = useState('');
    const [userOtp, setotp] = useState('');
    const [otp, setOTP] = useState(Math.floor(Math.random() * 100000));
    const [newPassword, setpassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    const [userDetails, setDetails] = useState({});
    const [showPassword, setShowPassword] = useState({
        one: false,
        two: false
    });
    const [Error, setError] = useState({
        passError: '',
        OtpError: ''
    })
    const round = 10;

    const toggleone = () => {
        setShowPassword({ ...showPassword, one: !showPassword.one })
    }

    const toggletwo = () => {
        setShowPassword({ ...showPassword, two: !showPassword.two })
    }

    const handleEmailSubmit = async (e) => {
        e.preventDefault()
        
        try {
            const res = await axios.post(`https://ingress-gateway.gaiansolutions.com/tf-web/v1.0/${process.env.REACT_APP_TENANTID}/schemas/${process.env.REACT_APP_SCHEMAID}/instances/list`, { email })

            if (res?.data?.msg == "entities not found for given criteria") {
                toast.error("Invalid credentials")
            }
            else {
                setDetails(res?.data?.entities[0])
                try {

                    var templateParams = {
                        first_name: res?.data?.entities[0]?.first_name,
                        last_name: res?.data?.entities[0]?.last_name,
                        email: res?.data?.entities[0]?.email,
                        otp
                    };
                    // console.log(email, otp);
                    emailjs.send('service_kjntv9f', 'template_89t36jr', templateParams, 'xCgvN9yLMo40nc7_a')
                        .then(function (response) {
                            // console.log('SUCCESS!', response.status, response.text);
                            setState(2);
                            toast.success("OTP Sent Successfully")
                        }, function (error) {
                            console.log('FAILED...', error);
                            toast("Something went wrong")
                            setState(1);
                        });
                }
                catch (err) {
                    console.log(err)
                }
            }

        } catch (err) {
            toast("Something went wrong")
        }



    };

    const handleOTPSubmit = async (e) => {
        e.preventDefault()
        // console.log(userOtp, typeof (userOtp));  //string
        // console.log(otp, typeof (otp))    //number
        // console.log(otp, userOtp, userOtp != otp)

        if (userOtp != parseInt(otp)) {
            setError({ ...Error, OtpError: "Incorrect OTP. Please try again" })
        }
        else {
            setState(3);
        }
    };

    const handlePasswordBlur = (e) => {
        if (newPassword && confirmPassword) {
            const newPassword = e.target.value;
            const isValid = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g.test(newPassword);
            if (!isValid) {
                setError({ ...Error, passError: "Password must meet the mandatory criteria" })
                toast.error("Password must meet the following criteria:At least 8 characters long,Contains at least one alphabetic character (a-z or A-Z),one numeric digit (0-9),one special character")
            }
            else {
                if (newPassword !== confirmPassword) {
                    setError({ ...Error, passError: "Passwords must be same" })
                }
                else {
                    setError({ ...Error, passError: "" })
                }
            }
        }

    };
    const handlePasswordSubmit = async (e) => {

        e.preventDefault()

        if (Error.passError?.length == 0 && newPassword === confirmPassword) {

            const newHash = await bcrypt.hash(newPassword, round)

            // console.log(newHash);

            setDetails({ ...userDetails, password1: newHash })

            let payload = userDetails

            payload.password1 = newHash
            // console.log(payload);
            try {
                const res = await axios.post(`https://ingress-gateway.gaiansolutions.com/tf-web/v1.0/${process.env.REACT_APP_TENANTID}/schemas/${process.env.REACT_APP_SCHEMAID}/instance?upsert=true`, payload)

                // console.log(res);
                navigate("/login")
                toast.success("Password Reset Successfully")
            } catch (err) {
                toast.error(err.message)

            }
        }
        else{
            toast.error("Error")

        }


    };


    if (step === 1) {
        return (
            <div className="formcontainer">
                <form>
                    <label className="small" htmlFor="email">Enter Email Address</label>
                    <input type="text" placeholder="Email" value={email} onChange={(e) => setemail(e.target.value)} name="email" />
                    <section className="bttnContainer">
                        <button onClick={handleEmailSubmit}>Submit</button>
                    </section>

                </form>
            </div>
        );
    } else if (step === 2) {
        return (
            <div className="formcontainer">
                <form>
                    <label className="small" >Enter OTP that is sent to your entered email address</label>
                    <input type="number" placeholder="otp" value={userOtp} onChange={(e) => setotp(e.target.value)} name="otp" />
                    {Error?.OtpError && <p style={{ color: "red" }}>{Error?.OtpError}</p>}
                    <section className="bttnContainer">
                        <button onClick={handleOTPSubmit}>Submit</button>
                    </section>
                </form>
            </div>
        );
    } else if (step === 3) {
        return (
            <div className="formcontainer">
                <form>
                    <div>
                        <label className="small" htmlFor="password">Enter Passwrod</label>
                        <div className="app_input_pass">
                            <input type={showPassword.one ? 'text' : 'password'} placeholder="Password" value={newPassword} onChange={(e) => setpassword(e.target.value)} onBlur={handlePasswordBlur} />
                           
                            <FontAwesomeIcon
                                icon={showPassword.one ? faEyeSlash : faEye}
                                onClick={toggleone}
                                className="app_input_pass_eye"
                            />
                            
                        </div>
                    </div>

                    <div>
                        <label className="small" htmlFor="password">Confirm Password</label>
                        <div className="app_input_pass">
                            <input type={showPassword.two ? 'text' : 'password'} placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setconfirmPassword(e.target.value)} onBlur={handlePasswordBlur} />
                            <FontAwesomeIcon
                                icon={showPassword.two ? faEyeSlash : faEye}
                                onClick={toggletwo}
                                className="app_input_pass_eye"
                            />

                        </div>
                    </div>
                    {Error?.passError && <p style={{ color: "red" }}>{Error?.passError}</p>}
                    <section className="bttnContainer">
                        <button onClick={handlePasswordSubmit}>Submit</button>
                    </section>

                </form>

            </div>
        );
    }
}