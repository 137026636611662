import React, { useRef, useState } from 'react';
import './LearningCard.css';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import 'animate.css';

const LearningCard = ( {title, illustration, description, navigatinglink} ) => {

    const learning_card = useRef(null);
    const [animate, setAnimate] = useState(false);

    // window.onscroll = () => {
    //     if (learning_card.current.getBoundingClientRect().top < window.innerHeight)
    //         setAnimate(true);
    //     else
    //         setAnimate(false);
    // }

    // console.log(learning_card)

    return(
        <>
            <div className={animate ? "app__learningcard animate__animated animate__slideInRight" : "app__learningcard"} ref={learning_card}>
                <div className="app__learningcard_informationcontainer">
                    <div className="app__learningcard_illustration">
                        <img src={illustration} alt={title + " Illustration"} />
                    </div>
                    <div className="app__learningcard_information">
                        <p className="app__learningcard_information-title p__subheading">
                            {title}
                        </p>
                        <p className="app__learningcard_information-description p__paragraph">
                            {description}
                        </p>
                        <Link to={navigatinglink} className="app__learningcard_navigate flex__center p__paragraph">
                            Learn More <MdOutlineKeyboardArrowRight className='dropdown_arrow' />
                        </Link>
                    </div>
                </div>
                {/* <div className="app__learningcard_navigation">
                    <Link to={navigatinglink} className="app__learningcard_navigation-navigatinglink">
                        <button type='button' className='flex__center p__paragraph'>Learn More <MdOutlineKeyboardArrowDown className='dropdown_arrow' /></button>
                    </Link>
                </div> */}
            </div>
        </>
    );
};

export default LearningCard;