import TPALogo from '../assets/logo/tpa_logo.svg';
import ThePlatformAcademyLogo from '../assets/logo/theplatformacademy_logo.svg';

import HeroImage from '../assets/images/hero_image.svg';
import BannerLarge1 from '../assets/images/BannerLarge1.svg';
import BannerMedium1 from '../assets/images/BannerMedium1.svg';
import BannerSmall1 from '../assets/images/BannerSmall1.svg';
import BannerLarge2 from '../assets/images/BannerLarge2.svg';
import BannerMedium2 from '../assets/images/BannerMedium2.svg';
import BannerSmall2 from '../assets/images/BannerSmall2.svg';

import Partnership_BrandAwareness from '../assets/illustrations/Partnership_BrandAwareness.svg';
import Partnership_BusinessGrowth from '../assets/illustrations/Partnership_BusinessGrowth.svg';
import Partnership_Community from '../assets/illustrations/Partnership_Community.svg';
import Partnership_Engineers from '../assets/illustrations/Partnership_Engineers.svg';
import Partnership_FirstMovers from '../assets/illustrations/Partnership_FirstMovers.svg';
import Partnership_IT from '../assets/illustrations/Partnership_IT.svg';
import Partnership_Ranking from '../assets/illustrations/Partnership_Ranking.svg';
import Partnership_Skill from '../assets/illustrations/Partnership_Skill.svg';
import Partnership_Workforce from '../assets/illustrations/Partnership_Workforce.svg';


import Learn_Learners from '../assets/illustrations/Learn_Learners.svg';
import Learn_SystemIntegrators from '../assets/illustrations/Learn_SystemIntegrators.svg';
import Learn_Education from '../assets/illustrations/Learn_Education.svg';

import Features_Access from '../assets/illustrations/Features_Access.svg';
import Features_BlendedLearning from '../assets/illustrations/Features_BlendedLearning.svg';
import Features_Certification from '../assets/illustrations/Features_Certification.svg';
import Features_CommunityAccess from '../assets/illustrations/Features_CommunityAccess.svg';
import Features_Customization from '../assets/illustrations/Features_Customization.svg';
import Features_FreeTraining from '../assets/illustrations/Features_FreeTraining.svg';
import Features_HandsOn from '../assets/illustrations/Features_HandsOn.svg';
import Features_HybridLearning from '../assets/illustrations/Features_HybridLearning.svg';
import Features_IndustryMentorship from '../assets/illustrations/Features_IndustryMentorship.svg';
import Features_LifetimeAccess from '../assets/illustrations/Features_LifetimeAccess.svg';
import Features_OfflineTraining from '../assets/illustrations/Features_OfflineTraining.svg';
import Features_OnlinePractice from '../assets/illustrations/Features_OnlinePractice.svg';
import Features_PlacementSupport from '../assets/illustrations/Features_PlacementSupport.svg';
import Features_RegularSupport from '../assets/illustrations/Features_RegularSupport.svg';

import IndiaMap from '../assets/images/IndiaMap.svg';

import SnaplogicLogo from '../assets/logo/SnapLogic_Logo.svg';
import SnaplogicMonogram_UR from '../assets/icon/SnapLogic_Monogram_UR.svg';
import SnaplogicMonogram_LL from '../assets/icon/SnapLogic_Monogram_LL.svg';
import SnaplogicMonogram from '../assets/logo/SnapLogic_Monogram.svg';

import MobiusLogo from '../assets/logo/Mobius_Logo.png';
import MobiusMonogram_UR from '../assets/icon/Mobius_Monogram_UR.svg';
import MobiusMonogram__LL from '../assets/icon/Mobius_Monogram_LL.svg';
import MobiusMonogram from '../assets/logo/Mobius_Monogram.svg';

import Course_Certification from '../assets/illustrations/Course_Certification.svg';
import Course_Integration from '../assets/illustrations/Course_Integration.svg';
import Course_Administration from '../assets/illustrations/Course_Administration.svg';
import Course_Architecture from '../assets/illustrations/Course_Architecture.svg';

import Course_DataEngineering from '../assets/illustrations/Course_DataEngineering.svg';
import Course_BusinessWorkflow from '../assets/illustrations/Course_BusinessWorkflow.svg';
import Course_Omnichannel from '../assets/illustrations/Course_Omnichannel.svg';
import Course_LowCode from '../assets/illustrations/Course_LowCode.svg';

import SLEdu_Events_Image from '../assets/images/SLEduEvent_image.svg';
import DEGaian_Events_Image from '../assets/images/DEGaianEvent_image.jpg';
import DSGaian_Events_Image from '../assets/images/DSGaianEvent_image.jpg';

import FacebookIcon from '../assets/icon/facebook_blue.svg';
import TwitterIcon from '../assets/icon/twitter_blue.svg';
import LinkedInIcon from '../assets/icon/linkedin_blue.svg';
import InstagramIcon from '../assets/icon/instagram_blue.svg';

import ConstructionVector_Left from '../assets/illustrations/Vector1.svg';
import ConstructionVector_Right from '../assets/illustrations/Vector2.svg';
import PageUnderConstruction from '../assets/illustrations/PageUnderConstruction.svg';

import NotFoundVector_Left from '../assets/illustrations/Vector3.svg';
import NotFoundVector_Right from '../assets/illustrations/Vector4.svg';
import FatalError from '../assets/illustrations/FatalError.svg';

import EventRegistration from '../assets/images/registration_image.svg';

import BookmarkIcon from '../assets/icon/bookmark_icon.svg';
import CalendarIcon from '../assets/icon/calendar_icon.svg';
import LocationIcon from '../assets/icon/location_icon.svg';

import BoostBusinessImage from '../assets/images/BoostBusiness.svg';
import StudentsFutureReady from '../assets/images/StudentsFutureReady.svg';
import BoostCareer from '../assets/images/BoostCareer.svg';

import CustomCourseImage from '../assets/images/CustomCourse.svg';

import ContactIllustration from '../assets/illustrations/Contact_Illustration.svg';

import EventBlog01_Image from '../assets/images/EventBlog01_Image.svg';

import Rectangle_Bacground_img from '../assets/images/rectangle.png'
import rectangle_1 from '../assets/images/Rectangle 1.png'
import rectangle_17 from '../assets/images/Rectangle 17.png'
import rectangle_18 from '../assets/images/Rectangle 18.png'
import rectangle_19 from '../assets/images/Rectangle 19.png'
import rectangle_20 from '../assets/images/Rectangle 20.png'




import TPA_evenntblog_01_header_img from '../assets/images/TPA_evenntblog_01/TPA_evenntblog_01_header_img.png';
import TPA_evenntblog_01_sec_img from '../assets/images/TPA_evenntblog_01/TPA_evenntblog_01_sec_img.png';
import TPA_evenntblog_01_third_img from '../assets/images/TPA_evenntblog_01/TPA_evenntblog_01_third_img.png';
import TPA_evenntblog_01_sankar from '../assets/images/TPA_evenntblog_01/TPA_evenntblog_01_sankar.png';
import TPA_evenntblog_01_neeraj from '../assets/images/TPA_evenntblog_01/TPA_evenntblog_01_neeraj.png';
import TPA_evenntblog_01_veenuA from '../assets/images/TPA_evenntblog_01/TPA_evenntblog_01_veenuA.png';
import TPA_evenntblog_01_group01_img01 from '../assets/images/TPA_evenntblog_01/TPA_evenntblog_01_group_img01.png';
import TPA_evenntblog_01_group01_img02 from '../assets/images/TPA_evenntblog_01/TPA_evenntblog_01_group_img02.png';
import TPA_evenntblog_01_group01_img03 from '../assets/images/TPA_evenntblog_01/TPA_evenntblog_01_group_img03.png';
import TPA_evenntblog_01_group01_img04 from '../assets/images/TPA_evenntblog_01/TPA_evenntblog_01_group_img04.png';
import TPA_evenntblog_01_group01_img05 from '../assets/images/TPA_evenntblog_01/TPA_evenntblog_01_group_img05.png';
import TPA_evenntblog_01_group02_img01 from '../assets/images/TPA_evenntblog_01/TPA_evenntblog_01_group02_img01.png';
import TPA_evenntblog_01_group02_img02 from '../assets/images/TPA_evenntblog_01/TPA_evenntblog_01_group02_img02.png';
import TPA_evenntblog_01_group02_img03 from '../assets/images/TPA_evenntblog_01/TPA_evenntblog_01_group02_img03.png';
import TPA_evenntblog_01_group02_img04 from '../assets/images/TPA_evenntblog_01/TPA_evenntblog_01_group02_img04.png';
import TPA_evenntblog_01_group02_img05 from '../assets/images/TPA_evenntblog_01/TPA_evenntblog_01_group02_img05.png';
import TPA_evenntblog_01_group02_img06 from '../assets/images/TPA_evenntblog_01/TPA_evenntblog_01_group02_img06.png';
import TPA_evenntblog_01_group02_img07 from '../assets/images/TPA_evenntblog_01/TPA_evenntblog_01_group02_img07.png';
import TPA_evenntblog_01_group02_img08 from '../assets/images/TPA_evenntblog_01/TPA_evenntblog_01_group02_img08.svg';
import TPA_evenntblog_01_group02_img09 from '../assets/images/TPA_evenntblog_01/TPA_evenntblog_01_group02_img09.png';


export default {
    TPALogo,
    ThePlatformAcademyLogo,

    HeroImage,
    BannerLarge1,
    BannerMedium1,
    BannerSmall1,
    BannerLarge2,
    BannerMedium2,
    BannerSmall2,

    Partnership_BrandAwareness,
    Partnership_BusinessGrowth,
    Partnership_Community,
    Partnership_Engineers,
    Partnership_FirstMovers,
    Partnership_IT,
    Partnership_Ranking,
    Partnership_Skill,
    Partnership_Workforce,

    Learn_Learners,
    Learn_SystemIntegrators,
    Learn_Education,

    Features_Access,
    Features_BlendedLearning,
    Features_Certification,
    Features_CommunityAccess,
    Features_Customization,
    Features_FreeTraining,
    Features_HandsOn,
    Features_HybridLearning,
    Features_IndustryMentorship,
    Features_LifetimeAccess,
    Features_OfflineTraining,
    Features_OnlinePractice,
    Features_PlacementSupport,
    Features_RegularSupport,

    IndiaMap,

    SnaplogicLogo,
    SnaplogicMonogram_UR,
    SnaplogicMonogram_LL,
    SnaplogicMonogram,

    MobiusLogo,
    MobiusMonogram_UR,
    MobiusMonogram__LL,
    MobiusMonogram,

    Course_Certification,
    Course_Integration,
    Course_Administration,
    Course_Architecture,

    Course_DataEngineering,
    Course_BusinessWorkflow,
    Course_Omnichannel,
    Course_LowCode,

    SLEdu_Events_Image,
    DEGaian_Events_Image,
    DSGaian_Events_Image,

    FacebookIcon,
    TwitterIcon,
    LinkedInIcon,
    InstagramIcon,

    ConstructionVector_Left,
    ConstructionVector_Right,
    PageUnderConstruction,

    NotFoundVector_Left,
    NotFoundVector_Right,
    FatalError,

    EventRegistration,

    BookmarkIcon,
    CalendarIcon,
    LocationIcon,

    BoostBusinessImage,
    StudentsFutureReady,
    BoostCareer,

    CustomCourseImage,

    ContactIllustration,

    EventBlog01_Image,
    
    Rectangle_Bacground_img,
    rectangle_1,
    rectangle_17,
    rectangle_18,
    rectangle_19,
    rectangle_20,

    TPA_evenntblog_01_header_img,
    TPA_evenntblog_01_sec_img,
    TPA_evenntblog_01_third_img,
    TPA_evenntblog_01_sankar,
    TPA_evenntblog_01_neeraj,
    TPA_evenntblog_01_veenuA,
    TPA_evenntblog_01_group01_img01,
    TPA_evenntblog_01_group01_img02,
    TPA_evenntblog_01_group01_img03,
    TPA_evenntblog_01_group01_img04,
    TPA_evenntblog_01_group01_img04,
    TPA_evenntblog_01_group01_img05,
    TPA_evenntblog_01_group02_img01,
    TPA_evenntblog_01_group02_img02,
    TPA_evenntblog_01_group02_img03,
    TPA_evenntblog_01_group02_img04,
    TPA_evenntblog_01_group02_img05,
    TPA_evenntblog_01_group02_img06,
    TPA_evenntblog_01_group02_img07,
    TPA_evenntblog_01_group02_img08,
    TPA_evenntblog_01_group02_img09
};