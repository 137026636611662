import React from 'react';
import './RegisteredCourseCard.css';
import { Link } from 'react-router-dom';

const RegisteredCourseCard = ( {image, title, navigatingLink} ) => {
    return(
        <div className="app__registeredcoursecard flex__center">
            <div className="app__registeredcoursecard-coursedetails flex__center">
                <div className="app__registeredcoursecard-coursedetails-image flex__center">
                    <img src={image} alt={title + " Illustration"} />
                </div>
                <div className="app__registeredcoursecard-coursedetails-title p__paragraph">
                    {title}
                </div>
            </div>
            {
                window.innerWidth > 600 &&
                <div className="vertical__bar"></div>
            }
            <div className="app__registeredcoursecard-navigation flex__center">
                <Link to="https://accounts.skilljar.com/accounts/login/?t=23kbjx5xu8vk9&d=1j0m3gk9de94&next=%2Fauth%2Fendpoint%2Flogin%2Fresult%3Fnext%3D%252F%26d%3D1j0m3gk9de94" target="_blank" className="app__registeredcoursecard-navigation-learn">
                    <button className='flex__center' type='button'>Learn now</button>
                </Link>
                <Link to={navigatingLink} className="app__registeredcoursecard-navigation-view">
                    <button className='flex__center' type='button'>View course details</button>
                </Link>
            </div>
        </div>
    );
};

export default RegisteredCourseCard;