import './BlogCard.css'
import { FiClock } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const BlogCard = ({ image, description, date, id }) => {
    return (
        <>
            <Link to={`/blogdetails/${id}`} className="app__blogcard">

                    <div className='app__blogcard_image'>
                        <img src={image} alt="Blog Image" />
                    </div>

                    <div className='app__blogcard_description flex__center'>
                        <p className='p__paragraph'>
                            {description}
                        </p>
                    </div >

                    <div className='app__blogcard_date'>
                        <FiClock className='clock__icon' />
                        <p className='flex__center'>{date}</p>
                    </div>

            </Link>
        </>
    )
};

export default BlogCard;