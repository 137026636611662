import { Footer, NavBar, ResetPassword } from "../container"


export const Reset_Password=()=>{

    return(
        <>
            <NavBar/>
            <ResetPassword/>
            {/* <Footer/> */}
        </>
    )
}