import React from 'react';
import { NavBar, SLEduSeriesEvent, Footer } from '../container';



const LaunchEvent = () => {
    return(
        <>
            <NavBar />
            <SLEduSeriesEvent />
            <Footer />
        </>
    );
};

export default LaunchEvent;