import React from 'react';
import './Landing.css';
import { images } from '../../constants';
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';

const Landing = () => {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 850 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 850, min: 650 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 650, min: 0 },
            items: 1
        }
    };

    return (
        <>
            <div className={"app__landing"}>
                {/* <div className="app__landing_hero">
                    {
                        window.innerWidth > 1150 &&
                        <img src={images.HeroImage} alt="Hero Image" />
                    }
                </div>
                <div className="app__landing_content flex__center">
                    <div className="app__landing_content-illustration" id="illustration-1"></div>
                    <div className="app__landing_content-title p__large flex__center">
                        Future proof your skills with our next generation upskilling and training programs
                    </div>
                    <div className="app__landing_content-illustration" id="illustration-2"></div>
                </div> */}
                {
                    window.innerWidth >= 1150 &&
                    <Carousel
                        className="app__landing-carousel"
                        swipeable={true}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        centerMode={false}
                        partialVisbile={false}
                        slidesToSlide={1}
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={5000}
                        keyBoardControl={true}
                        customTransition="all 1s"
                        transitionDuration={1000}
                        removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={50}
                    >
                        <>
                            <img src={images.BannerLarge1} alt="Banner" />
                            <div className="app__landing_signup-button flex__center">
                                <Link to='/all-courses'>
                                    <button className='landing__signup_button'>Learn More</button>
                                </Link>
                                <Link to='/course-registration'>
                                    <button className='landing__signup_button'>Register Now</button>
                                </Link>
                            </div>
                            {/* <Link to='/course-registration' className='app__landing_registration-button flex__center'>
                                <button className='landing__register_button'>Register for our upcoming training program <FaArrowRight className='right__arrow'/></button>
                            </Link> */}
                        </>

                        <>
                            <img src={images.BannerLarge2} alt="Banner" />
                            <div className="app__landing_signup-button flex__center">
                                <Link to='/all-courses'>
                                    <button className='landing__signup_button'>Learn More</button>
                                </Link>
                                <Link to='/course-registration'>
                                    <button className='landing__signup_button'>Register Now</button>
                                </Link>
                            </div>
                            {/* <Link to='/course-registration' className='app__landing_registration-button flex__center'>
                                <button className='landing__register_button'>Register for our upcoming training program <FaArrowRight className='right__arrow'/></button>
                            </Link> */}
                        </>
                    </Carousel>
                }
                {
                    window.innerWidth < 1150 &&
                    <Carousel
                        className="app__landing-carousel"
                        swipeable={true}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        centerMode={false}
                        partialVisbile={false}
                        slidesToSlide={1}
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={5000}
                        keyBoardControl={true}
                        customTransition="all 1s"
                        transitionDuration={1000}
                        removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={50}
                    >
                        <img src={images.BannerSmall1} alt="Banner" />
                        <img src={images.BannerSmall2} alt="Banner" />
                    </Carousel>
                }
            </div>
        </>
    );
}

export default Landing;
