import React from 'react';
import { NotFound } from '../container';
import { Footer } from '../container';



const Error = () => {
    const errorStyle = {
        width: '100%',
        minHeight: '100vh',
        backgroundColor: 'var(--color-white)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
    };

    return(
        <div style={ errorStyle }>
            <NotFound />
            <Footer />
        </div>
    );
};

export default Error;