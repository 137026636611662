import React from 'react';
import { NavBar, Mobius, MobiusCourses, Footer } from '../container';


const AboutMobius = () => {
    return(
        <>
            <NavBar />
            <Mobius type="MobiusInfo" />
            <MobiusCourses type="MobiusInfo" />
            <Footer />
        </>
    );
};

export default AboutMobius;