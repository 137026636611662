import React, { useContext } from 'react';
import './InfoCard.css';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../../constants/Context';



const InfoCard = ( {title, description, image} ) => {
    const { global_action } = useContext(GlobalContext);
    
    return(
        <>
            <div className="app__infocard flex__center">
                <div className="app__infocard-info">
                    <h3 className="app__infocard-info-title p__heading">
                        {title}
                    </h3>
                    <ul className='app__infocard-info-description'>
                        {
                            description.map( (desc) => <li className='p__paragraph'>{desc}</li>)
                        }
                    </ul>
                    <Link to="" className="app__infocard-info-register" onClick={ () => global_action( {contactusPopup: true} ) }>
                                <button type='button'>Register Now</button>
                    </Link>
                </div>
                <div className="app__infocard-image">
                    <img src={image} alt={title + " Image"} />
                </div>
            </div>
        </>
    );
};

export default InfoCard;