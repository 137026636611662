import images from "./images"



const Benefits = [
  {
    title: "Brand Awareness",
    illustration: images.Partnership_BrandAwareness
  },
  {
    title: "Business Growth",
    illustration: images.Partnership_BusinessGrowth
  },
  {
    title: "Community Building",
    illustration: images.Partnership_Community
  }
];



const WhyIndia = [
  {
    title: "Cost Efficient Workforce",
    illustration: images.Partnership_Workforce
  },
  {
    title: "First Movers Advantage",
    illustration: images.Partnership_FirstMovers
  },
  {
    title: "Deep Skill-Base",
    illustration: images.Partnership_Skill
  },
  {
    title: "No.1 Ranking in GSLI by Kearny",
    illustration: images.Partnership_Ranking
  },
  {
    title: "2M Engineers Graduate Each Year",
    illustration: images.Partnership_Engineers
  },
  {
    title: "1M Engineers Select IT Each Year",
    illustration: images.Partnership_IT
  }
];



const Learning = [
  {
    title: "For Learners",
    description: "Accelerated career | Jobs with higher income | Transition ready skills | Futuristic skillset",
    illustration: images.Learn_Learners,
    navigatinglink: "/learners"
  },
  {
    title: "For System Integrators",
    description: "Business agility | Employee Engagement | Proficiency certificates | Efficient workforce",
    illustration: images.Learn_SystemIntegrators,
    navigatinglink: "/system-integrators"
  },
  {
    title: "For Educational Institutes",
    description: "Enhanced Placements | Reputation & Rank | Future-proofed grads | Learning experience",
    illustration: images.Learn_Education,
    navigatinglink: "/educational-institutes"
  }
];



const FeatureList = [
  {
    title: <>Hybrid Learning<br></br><span>(Digital + Physical)</span></>,
    illustration: images.Features_HybridLearning
  },
  {
    title: "Full Lifetime Access",
    illustration: images.Features_LifetimeAccess
  },
  {
    title: "Community Access",
    illustration: images.Features_CommunityAccess
  },
  {
    title: <>Blended Learning<br></br><span>(Self Paced + Interactive)</span></>,
    illustration: images.Features_BlendedLearning
  },
  {
    title: "Certification of Completion",
    illustration: images.Features_Certification
  },
  {
    title: "Industry Mentoring",
    illustration: images.Features_IndustryMentorship
  },
  {
    title: "100% Free Training",
    illustration: images.Features_FreeTraining
  },
  {
    title: "Hands On Live Projects",
    illustration: images.Features_HandsOn
  },
  {
    title: "Placement Support",
    illustration: images.Features_PlacementSupport
  },
  {
    title: "Access to Online/Offline Events",
    illustration: images.Features_Access
  },
  {
    title: "Online Practice Lab Access",
    illustration: images.Features_OnlinePractice
  },
  {
    title: "Customization",
    illustration: images.Features_Customization
  },
  {
    title: "Offline Training On Demand",
    illustration: images.Features_OfflineTraining
  },
  {
    title: "Regular Academic Support",
    illustration: images.Features_RegularSupport
  }
];



const CityList = [
  "Delhi NCR",
  "Hyderabad, Telangana",
  "Mumbai, Maharashtra",
  "Pune, Maharashtra",
  "Chennai, Tamil Nadu",
  "Bengaluru, Karnataka"
];



const TopSnaplogicCourses = [
  {
    courseId: "SL_dev_01",
    title: "SnapLogic Certified Developer",
    illustration: images.Course_Certification,
    navigatinglink: "/snaplogic-courses/snaplogic-developer-certification"
  },
  {
    courseId: "SL_int_02",
    title: "SnapLogic Certified Integrator",
    illustration: images.Course_Integration,
    navigatinglink: "/snaplogic-courses/snaplogic-integrator-certification"
  },
  {
    courseId: "SL_arc_03",
    title: "SnapLogic Certified Architect",
    illustration: images.Course_Architecture,
    navigatinglink: "/snaplogic-courses/snaplogic-architect-certification"
  },
  {
    courseId: "SL_adm_04",
    title: "SnapLogic Certified Administrator",
    illustration: images.Course_Administration,
    navigatinglink: "/snaplogic-courses/snaplogic-administrator-certification"
  }
];



const TopMobiusCourses = [
  {
    courseId: "Mob_PI_01",
    title: "Data Engineering with Mobius PI",
    illustration: images.Course_DataEngineering,
    navigatinglink: ""
  },
  {
    courseId: "Mob_BOB_02",
    title: "Modelling Business Workflow with Mobius BoB",
    illustration: images.Course_BusinessWorkflow,
    navigatinglink: ""
  },
  {
    courseId: "Mob_MONET_03",
    title: "Low Code Cross-Platfrom App Development with Mobius Monet",
    illustration: images.Course_LowCode,
    navigatinglink: ""
  },
  {
    courseId: "Mob_VINCI_04",
    title: "Creating OmniChannel CX Journey with Mobius Vinci",
    illustration: images.Course_Omnichannel,
    navigatinglink: ""
  }
];



const AllSnaplogicCourses = [
  {
    courseId: "SL_dev_01",
    title: "SnapLogic Certified Developer",
    illustration: images.Course_Certification,
    navigatinglink: "/snaplogic-courses/snaplogic-developer-certification"
  },
  {
    courseId: "SL_int_02",
    title: "SnapLogic Certified Integrator",
    illustration: images.Course_Integration,
    navigatinglink: "/snaplogic-courses/snaplogic-integrator-certification"
  },
  {
    courseId: "SL_arc_03",
    title: "SnapLogic Certified Architect",
    illustration: images.Course_Architecture,
    navigatinglink: "/snaplogic-courses/snaplogic-architect-certification"
  },
  {
    courseId: "SL_adm_04",
    title: "SnapLogic Certified Administrator",
    illustration: images.Course_Administration,
    navigatinglink: "/snaplogic-courses/snaplogic-administrator-certification"
  },
  {
    courseId: "SL_ent_05",
    title: "SnapLogic Certified Enterprise Automation Professional",
    illustration: images.Course_Certification,
    navigatinglink: "/snaplogic-courses/snaplogic-enterprise-automation-professional-certification"
  },
  {
    courseId: "SL_acc_06",
    title: "SnapLogic Accreditation",
    illustration: images.Course_Certification,
    navigatinglink: "/snaplogic-courses/snaplogic-accreditation-certification"
  }
];



const AllMobiusCourses = [
  {
    courseId: "Mob_PI_01",
    title: "Data Engineering with Mobius PI",
    illustration: images.Course_DataEngineering,
    navigatinglink: ""
  },
  {
    courseId: "Mob_BOB_02",
    title: "Modelling Business Workflow with Mobius BoB",
    illustration: images.Course_BusinessWorkflow,
    navigatinglink: ""
  },
  {
    courseId: "Mob_MONET_03",
    title: "Low Code Cross-Platfrom App Development with Mobius Monet",
    illustration: images.Course_LowCode,
    navigatinglink: ""
  },
  {
    courseId: "Mob_VINCI_04",
    title: "Creating OmniChannel CX Journey with Mobius Vinci",
    illustration: images.Course_Omnichannel,
    navigatinglink: ""
  }
];



const SnaplogicCourseDetails = [
  {
    title: "SnapLogic Certified Developer",
    illustration: images.Course_Certification,
    description: "The SnapLogic Developer Certification is aimed at testing your expertise in using the SnapLogic SDK, implementing different parts of Snap functionality, and building custom Snaps.",
    about: <>
      This course contains 7 lessons:
      <ol>
        <li>Introduction to Snap Development &ndash; Learn how to create custom Snaps, about the development environment setup and internal working of Snaps and Snaplexes, and understand the various stages that a Snap undergoes during runtime.</li>
        <li>Getting started with Snap Development &ndash; Learn how to: import the Snap Maven Archetype to create a project, and about the interfaces and base classes that can be used for Snap Development.</li>
        <li>Packaging and Deploying Snap Packs &ndash; Learn how to: set up the environment and configurations required to package and deploy Snaps, package Snap class files as Snap Packs, and deploy a Snap Pack to SnapLogic.</li>
        <li>Creating Snap UI &ndash; Learn how to build a Snap User Interface, including elements in the settings, views, and info tabs.</li>
        <li>Suggesting inputs and populating schema &ndash; Learn how to: write code to suggest inputs for fields in the Snap UI, and populate input and target schemas.</li>
        <li>Validating inputs and processing data &ndash; Learn how to: use the inputs specified by the user and the incoming data from the upstream Snaps to write the processing logic, and send data to the subsequent downstream Snaps via the Output and Error views.</li>
        <li>Creating and using accounts &ndash; Learn how to associate account classes to Snaps and use these accounts from the Snap classes.</li>
      </ol>
      <br></br>
      The Certification Exam consists of multiple-choice questions &ndash; A set of multiple-choice questions that assess your knowledge and understanding of various SnapLogic SDK used for Snap Development and unit testing.
      <br></br>
      <br></br>
      <br></br>
      <i>*By signing up for this accreditation and training, you agree for us to send your name and email address to our third-party accreditation partners.</i>
    </>,
    curriculum: <>
      <b>SnapLogic Developer Training</b>
      <br></br>
      <ol>
        <li>Welcome to SnapLogic Developer Training</li>
        <li>Introduction to Snap Development</li>
        <li>Getting started with Snap Development</li>
        <li>Packaging and Deploying Snap Packs</li>
        <li>Creating Snap UI</li>
        <li>Suggesting inputs and populating schema</li>
        <li>Validating inputs and processing data</li>
        <li>Creating and using accounts</li>
      </ol>
      <br></br>
      <b>SnapLogic Unit Testing Framework</b>
      <ul>
        <li>SnapLogic Unit Testing Framework</li>
      </ul>
    </>,
    certification: <>
      <b>Quiz</b>
      <ul>
        <li>Part 1: MCQs</li>
      </ul>
    </>
  },
  {
    title: "SnapLogic Certified Integrator",
    illustration: images.Course_Integration,
    description: "The SnapLogic Integrator certification is aimed at testing your expertise on all the core aspects involved in using SnapLogic for various integration needs across the organization.",
    about: <>
      The SnapLogic Integrator Certification is aimed at testing your expertise on all the core aspects involved in using SnapLogic for various integration needs across industries and lines-of-business.
      <br></br>
      <br></br>
      To prepare for the certification, you will have to finish all courses outlined in the training path below. The Certification Exam is included in this purchase. A certification will be granted only upon the successful passing of the Certification Exam.
      <br></br>
      <br></br>
      Upon successful passing of the certification exam, you will be SnapLogic certified and issued a certificate. The certificate is valid for 2 years from the date of issuance.
      <br></br>
      <br></br>
      Before taking the certification exam, you must complete all of the courses and videos outlined in the Certification Path below:
      <br></br>
      <ol>
        <li>SnapLogic Beginner Training</li>
        <li>Creating and Managing Ultra Tasks</li>
        <li>Migrating Pipelines and Projects from one Org to Another using SnapLogic</li>
        <li>Metadata Snaps</li>
        <li>User Assistance Videos for most commonly used Endpoints (60 Videos | 15+ Endpoints). Endpoints for which we currently have videos include:</li>
        <ul>
          <li>SOAP</li>
          <li>S3</li>
          <li>SAP</li>
          <li>NetSuite</li>
          <li>Salesforce</li>
          <li>Anaplan</li>
          <li>JDBC</li>
          <li>JMS</li>
          <li>SQL Server</li>
          <li>ServiceNow</li>
          <li>Workday</li>
          <li>REST</li>
          <li>Redshift</li>
          <li>Big Data</li>
          <li>Oracle</li>
        </ul>
      </ol>
      <br></br>
      E-Learning users can credit the completion of the above-mentioned courses towards their certification if all of the required courses and videos have been completed within one year of the certification exam date.
      <br></br>
      <br></br>
      The Certification Exam consists of two parts:
      <br></br>
      <ol>
        <li>Multiple choice questions &ndash; a set of multiple-choice questions that assess your understanding of various SnapLogic concepts and implementation details.</li>
        <li>Scenario-based assessment &ndash; a set of 4 scenarios in which you must create Pipelines & Tasks to implement the described scenarios.</li>
      </ol>
      <br></br>
      The results of the multiple-choice questions are immediately displayed along with the answers that were incorrect.
      <br></br>
      <br></br>
      Note:
      <ol>
        <li>The artifacts created for the hands-on evaluation part of the certification will be validated by the SnapLogic team within two weeks.</li>
        <li>After we complete the assessment, you will receive an email notifying you of the results and the completion certificate upon the successful completion of the certification.</li>
      </ol>
      <br></br>
      <br></br>
      <br></br>
      <i>*By signing up for this accreditation and training, you agree for us to send your name and email address to our third-party accreditation partners.</i>
    </>,
    curriculum: <>
      <b>SnapLogic Beginner Training</b>
      <br></br>
      <ol>
        <li>SnapLogic Platform Login Credentials</li>
        <li>Lesson_Introduction to SnapLogic Intelligent Integration Platform</li>
        <li>Lesson_Platform Overview and Managing Assets</li>
        <li>Lesson_Creating and Managing Pipelines</li>
        <li>Lesson_Using Expression Language</li>
        <li>Lesson_Creating and Managing Tasks</li>
        <li>Lesson_Managing and Monitoring Assets</li>
        <li>Lesson_Modularizing and Reusing Pipelines</li>
      </ol>
      <br></br>
      <b>Anaplan User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Writing Documents to Anaplan_UC1</li>
        <li>Writing Record Documents From Workday to Anaplan_UC2</li>
        <li>Writing Data From SAP to Anaplan_UC3</li>
        <li>Migrating Data From one Anaplan Model to Another_UC4</li>
      </ol>
      <br></br>
      <b>JDBC User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Writing Data Using Generic JDBC Snaps UC 1</li>
        <li>Updating Data Using Generic JDBC Snaps UC 2</li>
        <li>Reading Data Using Generic JDBC UC 3</li>
        <li>Deleting Records Using Generic JDBC UC 4</li>
      </ol>
      <br></br>
      <b>JMS User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Writing Messages to a JMS Queue UC 1 New</li>
        <li>Reading Data From a JMS Queue UC 2 New</li>
        <li>Writing Messages With Dynamic Headers to a JMS Queue UC 3 New</li>
        <li>Using Client Acknowledgement Mode UC 4 New</li>
      </ol>
      <br></br>
      <b>NetSuite User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Searching records in NetSuite_UC 1</li>
        <li>Reading files from NetSuite_UC 2</li>
        <li>Upsert data from Salesforce to NetSuite_UC 3</li>
      </ol>
      <br></br>
      <b>Oracle User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Writing data to a table_UC 1</li>
        <li>Moving data from one table to another_UC 2</li>
        <li>Logging Parent-Child Pipelines' execution details_UC 3</li>
        <li>Passthrough without using Copy_UC 4</li>
      </ol>
      <br></br>
      <b>Redshift User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Sync data in Redshift UC 1</li>
        <li>Unloading data from Redshift to S3 UC 2</li>
        <li>Using Redshift Bulk Load UC 3</li>
        <li>Using the Redshift SCD 2 Snap UC 4</li>
      </ol>
      <br></br>
      <b>REST User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Sending Documents Using REST POST UC 1</li>
        <li>Retrieving Documents Using REST GET UC 2</li>
        <li>Consuming XML Using REST GET UC 3</li>
        <li>Exposing a Pipeline as a REST Service Using Ultra Tasks UC 4</li>
      </ol>
      <br></br>
      <b>S3 User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Writing to and reading from Amazon S3_UC 1</li>
        <li>Reading multiple files from S3_UC 2</li>
        <li>Deleting multiple files from S3_UC 3</li>
      </ol>
      <br></br>
      <b>Salesforce User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Creating Leads in Salesforce_UC 1</li>
        <li>Reading data from Salesforce_UC 2</li>
        <li>Salesforce and Workday bi-directional integration_UC 3</li>
        <li>Capturing outbound messages from Salesforce_UC 4</li>
      </ol>
      <br></br>
      <b>SAP User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Reading a customer master record using BAPI_UC 1</li>
        <li>Creating a sales order using BAPI_UC 2</li>
        <li>Receiving customer master IDocs using IDoc Listener_UC 3</li>
        <li>Creating a vendor master record using IDoc_UC 4</li>
      </ol>
      <br></br>
      <b>ServiceNow User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Joining date from Salesforce and ServiceNow_UC 1</li>
        <li>Migrating issues from Jira to ServiceNow_UC 2</li>
        <li>Creating marketing tasks in ServiceNow_UC 3</li>
        <li>Writing new hire data to ServiceNow_UAV4</li>
      </ol>
      <br></br>
      <b>SOAP User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Retrieving data using SOAP_UC 1</li>
        <li>Accessing SOAP Service using REST Service_UC 2</li>
      </ol>
      <br></br>
      <b>SQL Server User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Reading Data From SQL Server UseCase1</li>
        <li>Writing Data to SQL Server UseCase2</li>
        <li>Migrating Data Using Parallel Run UseCase3</li>
        <li>Generating Avro Schema UseCase4</li>
      </ol>
      <br></br>
      <b>Ultra Tasks</b>
      <br></br>
      <ol>
        <li>Creating Ultra Pipelines</li>
        <li>Creating and Managing Ultra Tasks</li>
        <li>Demonstration_Writing Data to SQL using Ultra Tasks</li>
        <li>Demonstration_Retrieving Data from a REST Service using Ultra Tasks</li>
        <li>Demonstration_Polling Files using Ultra Tasks</li>
        <li>Hands On Exercise-1</li>
        <li>Hands On Exercise-2</li>
      </ol>
      <br></br>
      <b>Workday User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Extracting Workday data and uploading it to NetSuite_UC 1</li>
        <li>Exporting Workday reports as a Service_UC 2</li>
        <li>Exporting new hire data from Workday_UC 3</li>
        <li>Writing user data to Workday_UC 4</li>
      </ol>
      <br></br>
      <b>SnapLogic - Continuous Integration and Continuous Delivery (Migration)</b>
      <br></br>
      <ol>
        <li>CICD-SnapLogic-Projects Promotion</li>
      </ol>
    </>,
    certification: <>
      <ol>
        <li>Introduction</li>
        <li>Part 1: Multiple Choice Questions</li>
        <li>Part 2: Scenario-based Assessment</li>
      </ol>
    </>
  },
  {
    title: "SnapLogic Certified Architect",
    illustration: images.Course_Architecture,
    description: "To become a certified SnapLogic Architect, the SnapLogic Architect Certification is aimed at testing your expertise on integration reference architectures for the SnapLogic platform. You will be tested on the various key strategies that you will have to perform as a SnapLogic architect.",
    // Duplicate About
    about: <>
      The course contains five lessons:
      <ol>
        <li>Introduction to SnapLogic Enterprise Integration Cloud: Learn about SnapLogic&apos;s architecture, key terms and concepts, and the functionality of the SnapLogic interfaces, i.e., the Designer, the Manager, and the Dashboard.</li>
        <li>SnapLogic Platform Users and Groups: Master how to create users and groups in SnapLogic, learn about different user groups, update user information, and reset user passwords.</li>
        <li>Managing the SnapLogic Instance via the Manager: Learn how to manage and monitor the SnapLogic instance from the Manager Interface. Also, you get an overview of the Manager interface, key user roles, and different organizational settings available to an administrator in SnapLogic.</li>
        <li>Monitoring a SnapLogic Instance via the Dashboard: Learn how to monitor your SnapLogic instance from the Dashboard pane and how to monitor pipeline and Snaplex health in SnapLogic.</li>
        <li>Snaplex Management: Learn about different types of Snaplexes and how to create, update, configure, and move a Groundplex.</li>
      </ol>
      <br></br>
      <br></br>
      <br></br>
      <i>*By signing up for this accreditation and training, you agree for us to send your name and email address to our third-party accreditation partners.</i>
    </>,
    curriculum: <>
      <ol>
        <li>SnapLogic Architecture</li>
        <li>Configuring and Managing a SnapLogic Instance</li>
        <li>Understanding Snaplex Requirements</li>
        <li>Managing Pipeline Lifecycle</li>
        <li>Performing Advanced Data Transformations</li>
        <li>SnapLogic API Management</li>
        <li>Resumable Pipelines</li>
        <li>Best Practices</li>
      </ol>
    </>,
    certification: <>
      <ul>
        <li>Introduction</li>
        <li>Architect Certification Exam</li>
      </ul>
    </>
  },
  {
    title: "SnapLogic Certified Administrator",
    illustration: images.Course_Administration,
    // Duplicate Description
    description: "The SnapLogic Administrator Certification is aimed at testing your expertise in using the SnapLogic SDK, implementing different parts of Snap functionality, and building custom Snaps.",
    about: <>
      To become a certified SnapLogic Administrator, the SnapLogic Administration Certification is aimed at testing your expertise in supporting the administrative-level tasks for your SnapLogic instance. You will be tested on the various administrative level tasks that you will have to perform as a SnapLogic administrator.
      <br></br>
      <br></br>
      <br></br>
      <i>*By signing up for this accreditation and training, you agree for us to send your name and email address to our third-party accreditation partners.</i>
    </>,
    curriculum: <>
      <b>SnapLogic Administrator Training</b>
      <br></br>
      <ol>
        <li>Introduction to SnapLogic Enterprise Integration Cloud</li>
        <li>SnapLogic Platform User and User Groups</li>
        <li>Managing a SnapLogic Instance</li>
        <li>Monitoring a SnapLogic Instance</li>
        <li>Snaplex Management</li>
      </ol>
    </>,
    certification: <>
      <ul>
        <li>Multiple Choice Questions</li>
      </ul>
    </>
  },
  {
    title: "SnapLogic Certified Enterprise Automation Professional",
    illustration: images.Course_Certification,
    description: "The SnapLogic Certified Enterprise Automation Professional is an advanced certification program for integrator personnel that meets or exceeds the training, experience, and examination requirements. It is an intermediate-level course that builds on the concepts introduced in the beginner training and SnapLogic Accreditation course.",
    about: <>
      The certification program is aimed at testing your expertise on all core concepts involved in using SnapLogic for various integration needs across industries and lines of business. This learning path gives Integrators complete insight into the rich and advanced features of the SnapLogic platform. It also provides an extensive set of hands-on exercises that teaches students to become very proficient on the SnapLogic platform.
      <br></br>
      <br></br>
      Upon successful passing of the certification exam, you will be SnapLogic certified and issued a certificate. The certificate is valid for 2 years from the date of issuance.
      <br></br>
      <br></br>
      To prepare for the certification, you will have to finish all the courses outlined in the training path below.
      <br></br>
      <br></br>
      <ol>
        <li>SnapLogic Enterprise Integration Cloud, its architecture, and some key terms, and concepts</li>
        <li>SnapLogic Platform overview and Managing Assets</li>
        <li>Creating, Managing, and monitoring SnapLogic workflow</li>
        <li>Usage of expression language to transform and manipulate data</li>
        <li>Creating and Managing Tasks</li>
        <li>Working with SnapLogic Manager and Dashboard Console</li>
        <li>Modularizing and Reusing Pipelines</li>
        <li>Creating and Managing Ultra Tasks</li>
        <li>Migrating Pipelines and Projects from one Org to another using SnapLogic Metadata</li>
        <li>User Assistance Videos for most commonly used Endpoints (50+ Videos | 15+ Endpoints | 10+ hours of learning). Endpoints for which we currently have videos include - Anaplan, JDBC, JMS, NetSuite, Oracle, Redshift, REST, S3, Salesforce, SAP, ServiceNow, SOAP, SQL Server, Workday.</li>
      </ol>
      <br></br>
      <br></br>
      You have 3 Levels of the SnapLogic Certified Enterprise Automation Professional Exam - Level I, Level II, and Level III:
      <br></br>
      <br></br>
      Level I of the exam tests your knowledge and comprehension.
      <br></br>
      <br></br>
      Level II of the exam tests your application and analysis.
      <br></br>
      <br></br>
      Level III of the exam requires you to showcase the same skills as Level II, but you should be able to make judgment calls here.
      <br></br>
      <br></br>
      A countdown timer is shown at each level in the quiz and when the timer has run out, the quiz is submitted automatically with whatever answers have been filled in so far. You must score 80% or more in each of these levels to clear this exam. The results are displayed immediately after you complete the assessment.
      <br></br>
      <br></br>
      In the event where the user fails to complete the certification within the said timeframe, the certification will be considered forfeited.
      <br></br>
      <br></br>
      <br></br>
      <i>*By signing up for this accreditation and training, you agree for us to send your name and email address to our third-party accreditation partners.</i>
    </>,
    curriculum: <>
      <b>SnapLogic Beginner Training</b>
      <br></br>
      <ol>
        <li>SnapLogic Platform Login Credentials</li>
        <li>Lesson_Introduction to SnapLogic Intelligent Integration Platform</li>
        <li>Lesson_Platform Overview and Managing Assets</li>
        <li>Lesson_Creating and Managing Pipelines</li>
        <li>Lesson_Using Expression Language</li>
        <li>Lesson_Creating and Managing Tasks</li>
        <li>Lesson_Managing and Monitoring Assets</li>
        <li>Lesson_Modularizing and Reusing Pipelines</li>
      </ol>
      <br></br>
      <b>Anaplan User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Writing Documents to Anaplan_UC1</li>
        <li>Writing Record Documents From Workday to Anaplan_UC2</li>
        <li>Writing Data From SAP to Anaplan_UC3</li>
        <li>Migrating Data From one Anaplan Model to Another_UC4</li>
      </ol>
      <br></br>
      <b>JDBC User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Writing Data Using Generic JDBC Snaps UC 1</li>
        <li>Updating Data Using Generic JDBC Snaps UC 2</li>
        <li>Reading Data Using Generic JDBC UC 3</li>
        <li>Deleting Records Using Generic JDBC UC 4</li>
      </ol>
      <br></br>
      <b>JMS User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Writing Messages to a JMS Queue UC 1 New</li>
        <li>Reading Data From a JMS Queue UC 2 New</li>
        <li>Writing Messages With Dynamic Headers to a JMS Queue UC 3 New</li>
        <li>Using Client Acknowledgement Mode UC 4 New</li>
      </ol>
      <br></br>
      <b>NetSuite User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Searching records in NetSuite_UC 1</li>
        <li>Reading files from NetSuite_UC 2</li>
        <li>Upsert data from Salesforce to NetSuite_UC 3</li>
      </ol>
      <br></br>
      <b>Oracle User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Writing data to a table_UC 1</li>
        <li>Moving data from one table to another_UC 2</li>
        <li>Logging Parent-Child Pipelines' execution details_UC 3</li>
        <li>Passthrough without using Copy_UC 4</li>
      </ol>
      <br></br>
      <b>Redshift User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Sync data in Redshift UC 1</li>
        <li>Unloading data from Redshift to S3 UC 2</li>
        <li>Using Redshift Bulk Load UC 3</li>
        <li>Using the Redshift SCD 2 Snap UC 4</li>
      </ol>
      <br></br>
      <b>REST User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Sending Documents Using REST POST UC 1</li>
        <li>Retrieving Documents Using REST GET UC 2</li>
        <li>Consuming XML Using REST GET UC 3</li>
        <li>Exposing a Pipeline as a REST Service Using Ultra Tasks UC 4</li>
      </ol>
      <br></br>
      <b>S3 User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Writing to and reading from Amazon S3_UC 1</li>
        <li>Reading multiple files from S3_UC 2</li>
        <li>Deleting multiple files from S3_UC 3</li>
      </ol>
      <br></br>
      <b>Salesforce User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Creating Leads in Salesforce_UC 1</li>
        <li>Reading data from Salesforce_UC 2</li>
        <li>Salesforce and Workday bi-directional integration_UC 3</li>
        <li>Capturing outbound messages from Salesforce_UC 4</li>
      </ol>
      <br></br>
      <b>SAP User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Reading a customer master record using BAPI_UC 1</li>
        <li>Creating a sales order using BAPI_UC 2</li>
        <li>Receiving customer master IDocs using IDoc Listener_UC 3</li>
        <li>Creating a vendor master record using IDoc_UC 4</li>
      </ol>
      <br></br>
      <b>Service Now User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Joining date from Salesforce and ServiceNow_UC 1</li>
        <li>Migrating issues from Jira to ServiceNow_UC 2</li>
        <li>Creating marketing tasks in ServiceNow_UC 3</li>
        <li>Writing new hire data to ServiceNow_UAV4</li>
      </ol>
      <br></br>
      <b>SOAP User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Retrieving data using SOAP_UC 1</li>
        <li>Accessing SOAP Service using REST Service_UC 2</li>
      </ol>
      <br></br>
      <b>SQL Server User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Reading Data From SQL Server UseCase1</li>
        <li>Writing Data to SQL Server UseCase2</li>
        <li>Migrating Data Using Parallel Run UseCase3</li>
        <li>Generating Avro Schema UseCase4</li>
      </ol>
      <br></br>
      <b>Workday User Assistance Videos</b>
      <br></br>
      <ol>
        <li>Extracting Workday data and uploading it to NetSuite_UC 1</li>
        <li>Exporting Workday reports as a Service_UC 2</li>
        <li>Exporting new hire data from Workday_UC 3</li>
        <li>Writing user data to Workday_UC 4</li>
      </ol>
      <br></br>
      <b>Ultra Tasks</b>
      <br></br>
      <ol>
        <li>Creating Ultra Pipelines</li>
        <li>Creating and Managing Ultra Tasks</li>
        <li>Demonstration_Writing Data to SQL using Ultra Tasks</li>
        <li>Demonstration_Retrieving Data from a REST Service using Ultra Tasks</li>
        <li>Demonstration_Polling Files using Ultra Tasks</li>
        <li>Hands On Exercise-1</li>
        <li>Hands On Exercise-2</li>
      </ol>
      <br></br>
      <b>SnapLogic - Continuous Integration and Continuous Delivery (Migration)</b>
      <br></br>
      <ol>
        <li>CICD-SnapLogic-Projects Promotion</li>
      </ol>
    </>,
    certification: <>
      <ol>
        <li>SnapLogic Certified Enterprise Automation Professional Exam - Level I</li>
        <li>SnapLogic Certified Enterprise Automation Professional Exam - Level II</li>
        <li>SnapLogic Certified Enterprise Automation Professional Exam - Level III</li>
      </ol>
    </>
  },
  {
    title: "SnapLogic Accreditation",
    illustration: images.Course_Certification,
    description: "SnapLogic Accreditation is designed for those who want to enhance their expertise on all core SnapLogic concepts for various integration needs across industries and lines of business.",
    about: <>
      The SnapLogic Integrator Training teaches all of the concepts and tasks involved in using the SnapLogic Enterprise Integration Cloud to integrate various end-points. It also teaches how to manage and monitor your SnapLogic instance.
      <br></br>
      <br></br>
      It also contains hands-on exercises for practice. So, ensure that you have access to your SnapLogic Enterprise Integration Platform instance.
      <br></br>
      <br></br>
      Upon completion of these modules, you are required to take the SnapLogic Accreditation exam to get SnapLogic certified.
      <br></br>
      <br></br>
      The SnapLogic Accreditation test comprises 25 multiple-choice questions. You must score at least 60 percent to pass the test.
      <br></br>
      <br></br>
      Get started and become SnapLogic-accredited today!
      <br></br>
      <br></br>
      <br></br>
      <i>*By signing up for this accreditation and training, you agree for us to send your name and email address to our third-party accreditation partners.</i>
    </>,
    curriculum: <>
      <b>SnapLogic Integrator Training</b>
      <br></br>
      <ol>
        <li>Introduction to SnapLogic Enterprise Integration Cloud</li>
        <li>Platform Overview and Managing Assets</li>
        <li>Importing Projects</li>
        <li>Creating and Managing Pipelines</li>
        <li>Creating a Pipeline</li>
        <li>ReadingAssignment_CoreSnaps</li>
        <li>Creating a Pipeline</li>
        <li>Using Expression Language</li>
        <li>Using Expression Language</li>
        <li>ReadingAssignment_Expression Language</li>
        <li>Using Expression Language</li>
        <li>Creating and Managing Tasks</li>
        <li>Creating Tasks</li>
        <li>Managing and Monitoring Assets</li>
        <li>Modularizing and Reusing Pipelines</li>
        <li>Using the Pipeline Execute Snap</li>
        <li>HandsOn_Reusing Pipelines</li>
        <li>Extracting Data from Workday and Writing it to NetSuite</li>
        <li>Migrating Account Records from Salesforce to NetSuite</li>
        <li>Migrating Customer Records from Redshift to NetSuite</li>
        <li>Sending Twitter Reports via Email</li>
      </ol>
    </>,
    certification: <>
      SnapLogic Integrator Accreditation—Assessment
    </>
  }
];



const EventList = [
  {
    id: "GaianEvent03_DataScience",
    title: "AI/ML & Data Science Revolution: Gaian Solutions' Multi-City Talent Hunt 2023",
    description: <>
      <br></br>
      <br></br>
      <span className='p__paragraph'>11:00 am - 04:00 pm | 6th May 2023</span>
      <br></br>
      <br></br>
      Data Science is becoming increasingly important in today's world as organizations strive to leverage the vast amounts of data they collect to gain insights and make better decisions. It involves using statistical and computational methods to extract knowledge and insights from data, and has applications in fields ranging from business and finance to healthcare and science.
      <br></br>
      <br></br>
      A lot of folks unlocked their potential and elevated their career at Gaian Solutions' AI/ML & Data Science Revolution, a multi-city job fair crafted exclusively for talented data scientists and AI/ML professionals like you.
    </>,
    image: images.DSGaian_Events_Image,
    navigatingLink: "/data-science"
  },
  {
    id: "GaianEvent02_DataEngn",
    title: "Data Engineering Talent Hunt at Gaian Solutions: Multi-city Job Fair 2023",
    description: <>
      <br></br>
      <br></br>
      <span className='p__paragraph'>11:00 am - 04:00 pm | 6th May 2023</span>
      <br></br>
      <br></br>
      Data engineering comprises of designing, building, and managing the infrastructure that enables our organization to store, process, and analyze large amounts of data. It involves the use of various tools and technologies to transform raw data into a usable format for analysis and business intelligence purposes.
      <br></br>
      <br></br>
      Data engineering encompasses a wide range of tasks, including data acquisition, data transformation, data storage, data processing, and data analysis.
      <br></br>
      <br></br>
      Thank you for joining us for the exciting multi city launch of SnapLogic Tool Academy and an exclusive Data Engineering Talent Hunt at Gaian Solutions, a job fair tailored for skilled data engineering professionals like you.
    </>,
    image: images.DEGaian_Events_Image,
    navigatingLink: "/data-engineering"
  },
  {
    id: "GaianEvent01_SLEdu",
    title: "SL EduSeries - ETL &  Integration Job Seminar",
    description: <>
      <br></br>
      <br></br>
      <span className='p__paragraph'>11:00 am - 04:00 pm | 6th May 2023</span>
      <br></br>
      <br></br>
      SL EduSeries on the occasion of SnapLogic Academy India launch event was conducted . It was an exciting day filled with valuable insights, informative discussions, and networking opportunities. The event was designed for IT professionals, business leaders, and anyone interested in the space of Data Engineering, Automation, Integration and iPaaS.
      <br></br>
      <br></br>
      The event kicked off with a keynote address from a prominent industry leader, followed by a speech about the twin disruption of AI and No-Code. The keynote was followed by a panel discussion on ETL and Data Integration, where industry leaders shared their experiences, best practices, and lessons in this space.
    </>,
    image: images.SLEdu_Events_Image,
    navigatingLink: "/sledu-series"
  }
];



const TPALaunchEvent = {
  eventTitle: "Launch Event - The Platform Academy",
  eventType: "Launch Event",
  eventDate: "6th May 2023 | 11:00 AM IST",
  eventLocation: "Hyderabad | Delhi | Bengaluru | Chennai | Pune | Mumbai",
  navigatingLink: "",
  eventImage: images.EventRegistration,
  eventDescription: <>
    Welcome to the Launch event of "The Platform Academy"! Join us for an exciting day filled with valuable insights, informative discussions, and networking opportunities. This event is designed for IT professionals, business leaders, and anyone interested in the latest trends and innovations in the world of technology.
    <br></br>
    <br></br>
    The event will kick off with a keynote address from a prominent industry leader, followed by a speech about the twin disruption of AI and No-Code, and how it's changing the game for businesses worldwide. The keynote will be followed by a panel discussion on ETL and Data Integration, where industry leaders will share their experiences, best practices, and lessons learned in this space.
    <br></br>
    <br></br>
    We will also be giving an overview of the SnapLogic Academy India, which offers world-class training and certification programs. This Launch event will take place across six IT hubs in India, including Mumbai, Bengaluru, Hyderabad, Chennai, Delhi, and Pune. We welcome you to join us at the venue nearest to you and take advantage of this unique opportunity to connect with like-minded professionals and gain insights into the latest technologies and trends shaping our industry.
  </>,
  eventItinerary: [
    {
      time: <b>11:00 AM</b>,
      details: <>
        <b>Keynote</b>
        <br></br>
        Software Engineer Jobs in AI dominant IT sectors
      </>
    },
    {
      time: <b>11:45 AM</b>,
      details: <>
        <b>The Looking Glass Of The Coming Decade</b>
        <br></br>
        Twin Disruption of A.I and No-Code
      </>
    },
    {
      time: <b>12:30 PM</b>,
      details: <>
        <b>Lunch</b>
      </>
    },
    {
      time: <b>01:45 PM</b>,
      details: <>
        <b>Introduction Followed By Panel Discussion</b>
        <br></br>
        SnapLogic Overview
        <br></br>
        Panel Discussion on ETL & Data Integration
      </>
    },
    {
      time: <b>02:45 PM</b>,
      details: <>
        <b>Academy Offering</b>
        <br></br>
        SnapLogic Academy India - Course Offering & Registrations
      </>
    },
    {
      time: <b>04:00 PM</b>,
      details: <>
        <b>Conclude</b>
      </>
    }
  ],
  eventVenue: [
    {
      city: "Mumbai",
      address: "Hyatt Centric Juhu"
    },
    {
      city: "Bengaluru",
      address: "Hyatt Centric MG Road Bengaluru"
    },
    {
      city: "Chennai",
      address: "Novotel Chennai Chamiers Road Hotel"
    },
    {
      city: "Hyderabad",
      address: "Hyatt Place, Banjara Hills"
    },
    {
      city: "Delhi NCR",
      address: "Hyatt Place Gurgaon"
    },
    {
      city: "Pune",
      address: "Courtyard by Marriott Pune, Hinjewadi"
    }
  ],
  eventStatus: "Concluded"
};



const SlEduSeries = {
  eventTitle: <>
    SL EduSeries
    <p className="p__paragraph">ETL & Integration Job Seminar</p>
  </>,
  eventType: "SL Academy India Launch Event",
  eventDate: "6th May 2023 | 11:00 AM IST",
  eventLocation: "Hyderabad | Delhi | Bengaluru | Chennai | Pune | Mumbai",
  navigatingLink: "",
  eventImage: images.EventRegistration,
  eventDescription: <>
    Welcome to the ETL & Integration Job Seminar by  SL EduSeries on the occasion of SnapLogic Academy India launch event. Join us for an exciting day filled with valuable insights, informative discussions, and networking opportunities. This event is designed for IT professionals, business leaders, and anyone interested in the latest trends and innovations in the world of technology.
    <br></br>
    <br></br>
    The event will kick off with a keynote address from a prominent industry leader, followed by a speech about the twin disruption of AI and No-Code, and how it's changing the game for businesses worldwide. The keynote will be followed by a panel discussion on ETL and Data Integration, where industry leaders will share their experiences, best practices, and lessons learned in this space.
    <br></br>
    <br></br>
    We will also be giving an overview of the SnapLogic Academy India, which offers world-class training and certification programs. This Launch event will take place across six IT hubs in India, including Mumbai, Bengaluru, Hyderabad, Chennai, Delhi, and Pune. We welcome you to join us at the venue nearest to you and take advantage of this unique opportunity to connect with like-minded professionals and gain insights into the latest technologies and trends shaping our industry.
  </>,
  eventItinerary: [
    {
      time: <b>11:00 AM</b>,
      details: <>
        <b>Keynote</b>
        <br></br>
        Software Engineer Jobs in AI dominant IT sectors
      </>
    },
    {
      time: <b>11:45 AM</b>,
      details: <>
        <b>The Looking Glass for the Coming Decade</b>
        <br></br>
        Preparing for stellar IT career: 2023-2032
      </>
    },
    {
      time: <b>12:30 PM</b>,
      details: <>
        <b>Lunch</b>
      </>
    },
    {
      time: <b>01:45 PM</b>,
      details: <>
        <b>SnapLogic iPaaS Overview</b>
        <br></br>
        Panel Discussion on ETL & Data Integration
      </>
    },
    {
      time: <b>02:45 PM</b>,
      details: <>
        <b>Academy Offering</b>
        <br></br>
        SnapLogic Academy India - Course & SignUps
      </>
    },
    {
      time: <b>04:00 PM</b>,
      details: <>
        <b>Conclusion</b>
      </>
    }
  ],
  eventVenue: [
    {
      city: "Mumbai",
      address: "Hyatt Centric Juhu"
    },
    {
      city: "Bengaluru",
      address: "Hyatt Centric MG Road Bengaluru"
    },
    {
      city: "Chennai",
      address: "Novotel Chennai Chamiers Road Hotel"
    },
    {
      city: "Hyderabad",
      address: "Hyatt Place, Banjara Hills"
    },
    {
      city: "Delhi NCR",
      address: "Hyatt Place Gurgaon"
    },
    {
      city: "Pune",
      address: "Courtyard by Marriott Pune, Hinjewadi"
    }
  ],
  eventStatus: "Concluded"
};



const DataEngineeringJobFair = {
  eventTitle: <>
    Data Engineering Talent Hunt at Gaian Solutions
    <p className="p__paragraph">Multi-city Job Fair 2023</p>
  </>,
  eventType: "Data Engineering Talent Hunt Event",
  eventDate: "6th May 2023 | 11:00 AM IST",
  eventLocation: "Hyderabad | Delhi | Bengaluru | Chennai | Pune | Mumbai",
  navigatingLink: "",
  eventImage: images.EventRegistration,
  eventDescription: <>
    Join us for the exciting multi city launch of SnapLogic Tool Academy and an exclusive Data Engineering Talent Hunt at Gaian Solutions, a job fair tailored for skilled data engineering professionals like you.
    <br></br>
    <br></br>
    At Gaian Solutions, we are proud to offer exciting roles working on our innovative Digital Transformation-as-a-Service (DTaaS) platform. As a data engineer with us, you'll have the opportunity to:
    <ul>
      <li>
        Work with a variety of cutting-edge technologies, including Big Data, Hadoop, Phoenix, PostgreSQL, MongoDB, Redis, Elasticsearch, Kafka, and more.
      </li>
      <li>
        Leverage BPMN 2.0 notations and low-code tools to create effective and efficient data solutions.
      </li>
      <li>
        Explore multiple domains and develop solutions that drive innovation and growth.
      </li>
    </ul>
    <br></br>
    Event Highlights:
    <ul>
      <li>
        SnapLogic Tool Academy Launch: Be the first to experience our comprehensive training program dedicated to mastering the SnapLogic integration platform and ETL tools.
      </li>
      <li>
        Connect with Gaian Solutions' team members and explore career opportunities through walk-in interviews.
      </li>
      <li>
        Gain insights into our unique projects and technologies during panel discussions and presentations.
      </li>
      <li>
        Polish your resume and interview skills with our on-site career coaches and experts.
      </li>
      <li>
        Network with fellow data engineers and expand your professional connections.
      </li>
    </ul>
    <br></br>
    Don't miss this unique opportunity to kickstart your career at Gaian Solutions and be part of the SnapLogic Platform Academy launch! Register now for FREE and unlock access to exclusive resources and company profiles: <a href="www.gaianjobfair.com" target="_blank">www.gaianjobfair.com</a>
    <br></br>
    <br></br>
    Join us at the Data Engineering Talent Hunt and become part of Gaian Solutions' dynamic team. We look forward to meeting you!
    <br></br>
    <br></br>
    #GaianSolutions #DataEngineering #JobFair #SnapLogicToolAcademy #CareerOpportunities#TechJobs
  </>,
  eventVenue: [
    {
      city: "Mumbai",
      address: "Hyatt Centric Juhu"
    },
    {
      city: "Bengaluru",
      address: "Hyatt Centric MG Road Bengaluru"
    },
    {
      city: "Chennai",
      address: "Novotel Chennai Chamiers Road Hotel"
    },
    {
      city: "Hyderabad",
      address: "Hyatt Place, Banjara Hills"
    },
    {
      city: "Delhi NCR",
      address: "Hyatt Place Gurgaon"
    },
    {
      city: "Pune",
      address: "Courtyard by Marriott Pune, Hinjewadi"
    }
  ],
  eventStatus: "Concluded"
};



const DataScienceJobFair = {
  eventTitle: <>
    AI/ML & Data Science Revolution
    <p className="p__paragraph">Gaian Solutions' Multi-City Talent Hunt 2023</p>
  </>,
  eventType: "AI/ML and Data Science Talent Hunt Event",
  eventDate: "6th May 2023 | 11:00 AM IST",
  eventLocation: "Hyderabad | Delhi | Bengaluru | Chennai | Pune | Mumbai",
  navigatingLink: "",
  eventImage: images.EventRegistration,
  eventDescription: <>
    Unlock your potential and elevate your career at Gaian Solutions' AI/ML & Data Science Revolution, a multi-city job fair crafted exclusively for talented data scientists and AI/ML professionals like you.
    <br></br>
    <br></br>
    At Gaian Solutions, we empower our data scientists to:
    <ul>
      <li>
        Develop groundbreaking AI/ML algorithms and models, tackling the most complex business challenges.
      </li>
      <li>
        Harness state-of-the-art technologies, including Python, TensorFlow, Keras, and PyTorch.
      </li>
      <li>
        Collaborate with multidisciplinary teams to deliver data-driven solutions that reshape industries.
      </li>
      <li>
        Explore diverse domains, fostering innovation and growth.
      </li>
    </ul>
    <br></br>
    Event Highlights:
    <ul>
      <li>
        Multi-City AI/ML & Data Science Talent Hunt: Seize the opportunity to network with Gaian Solutions' experts and secure your dream job through walk-in interviews in six major cities across India.
      </li>
      <li>
        Engaging panel discussions and presentations, unveiling our trailblazing projects and cutting-edge technologies.
      </li>
      <li>
        On-site career coaches and experts, providing invaluable guidance to refine your resume and sharpen your interview skills.
      </li>
      <li>
        Connect with like-minded data scientists and AI/ML professionals, expanding your professional network.
      </li>
    </ul>
    <br></br>
    Embark on a transformative journey with Gaian Solutions and be part of the multi-city AI/ML & Data Science Revolution! Register now for FREE and gain access to exclusive resources and company profiles: <a href="www.gaianjobfair.com" target="_blank">www.gaianjobfair.com</a>
    <br></br>
    <br></br>
    Join the AI/ML & Data Science Revolution and become an integral part of Gaian Solutions' visionary team. We can't wait to meet you!
    <br></br>
    <br></br>
    #GaianSolutions #DataScience #AIML #MultiCityJobFair #CareerOpportunities #TechJobs #AIRevolution
  </>,
  eventVenue: [
    {
      city: "Mumbai",
      address: "Hyatt Centric Juhu"
    },
    {
      city: "Bengaluru",
      address: "Hyatt Centric MG Road Bengaluru"
    },
    {
      city: "Chennai",
      address: "Novotel Chennai Chamiers Road Hotel"
    },
    {
      city: "Hyderabad",
      address: "Hyatt Place, Banjara Hills"
    },
    {
      city: "Delhi NCR",
      address: "Hyatt Place Gurgaon"
    },
    {
      city: "Pune",
      address: "Courtyard by Marriott Pune, Hinjewadi"
    }
  ],
  eventStatus: "Concluded"
};



const BoostYourBusiness = {
  title: "Boost Your Business With Our Platform!",
  description: [
    "Business Readiness",
    "Efficient Workforce",
    "Proficiency Stamp",
    "Community Building",
    "Hiring Support",
    "Employee Engagement",
    "Satisfaction",
    "Cost Saving"
  ],
  image: images.BoostBusinessImage
};



const StudentsFutureReady = {
  title: "Make Your Students Future Ready!",
  description: [
    "Improved Placement Statistics",
    "Incubation Opportunities",
    "Status Rank",
    "Better Reputation",
    "Outcome Enhancements for Students",
    "Future Ready Graduates",
    "Better Learning Experience",
    "Internship Opportunities for Students"
  ],
  image: images.StudentsFutureReady
};



const BoostYourCareer = {
  title: "Boost Your Career With Our Platform!",
  description: [
    "Transition Ready Skills",
    "Higher Income Jobs",
    "Accelerated Career",
    "Employability Enhancement",
    "Futuristic Skillset",
    "Professional Confidence",
    "Flexible Learning",
    "Personalized Learning"
  ],
  image: images.BoostCareer
};



const CustomCourse = {
  title: "Want a custom course?",
  description: <>
    Create a Vibrant community of trained professionals and tech organisations for unlocking the growth and adoption of your platform.
    <br></br>
    <br></br>
    We provide a single point solution to start and grow the education and training of your platform across India.
  </>,
  image: images.CustomCourseImage
};



const EventBlogList = [
  {
    id: "eventblog_01",
    images: images.EventBlog01_Image,
    description: "SnapLogic Academy Launching in India: A Win for Global Enterprise Clients, Indian IT Services Giants, and Career Aspirants",
    date: "06th May 2023"
  }
];



const EducationalBlogList = [

];



const SLAcademyLaunchBlogDescription = [
  {
    id: "eventblog_01",
    header_img: images.TPA_evenntblog_01_header_img,
    title: <>
      <p>SnapLogic Academy Launching in India: </p>
      <p>
        A Win for Global Enterprise  Clients, Indian IT Services Giants, and Career Aspirants</p>
    </>,
    sec_section: {
      description_p1: "SnapLogic has consistently scored high in the Gartner Magic Quadrant and has acquired an impressive list of blue-chip enterprises who consume the SnapLogic product in mission-critical applications for their enterprise. With the launch of SnapLogic Academy in India, the platform is set to deepen its global availability of talent pool of trained and certified workforce presence from the region which serves as the integration factory of the globe.",
      description_p2: "For global enterprise clients whose back-office operations are typically based out of India, the launch of SnapLogic Academy is a win as it ensures that their workforce has access to world-class training, thereby increasing the efficiency and productivity of their workforce. This move will also help Indian IT services giants to stay competitive and win new business globally as they need to constantly upskill their workforce to deliver the best solutions to their clients.",
      image: images.TPA_evenntblog_01_sec_img,
      image_title: <>
        Future of software jobs<br />
        by Shivam Dikshit , AVP infoedge
      </>
    },
    third_section: "Moreover, this launch is also a win for career aspirants who are looking for new skills in these uncertain times of layoffs, AI triggered job cuts, and the era of low-code platforms. The SnapLogic Academy will offer a comprehensive curriculum designed to upskill individuals and help them learn the latest tools and technologies. This move will help aspirants to be better equipped to take on the challenges of the digital world and stand out in the job market and command higher compensation packages.",
    fourth_section: {
      title: "SnapLogic Academy ",
      description_p1: "The SnapLogic Academy is set to offer training courses that cater to different skill levels, ranging from beginners to advanced levels. The training programs will cover different topics such as integration patterns, API management, data transformation, Snap Development, Building Data Pipelines, managing data quality, among others. The training courses will be offered online, and as in person meetups making it adaptable and customizable for diverse training styles and needs. This move is aligned with the company's vision of democratizing integration and making it accessible to everyone.",
      description_p2: "The SnapLogic Academy is meant to create a community of practicing SnapLogic platform technologists. It offers certifications on multiple career paths, ranging from an ETL developer to a solution architect. The academy is expected to offer meetups, hackathons, job fairs, and career counseling services as it embarks on a highly ambitious initiative. The academy is designed to cater to the different skill levels, ranging from beginners to advanced levels.",
      description_p3: "The target is to train a minimum of 6000 personnel in the first 12 months. "
    },
    fifth_section: {
      image: images.TPA_evenntblog_01_third_img,
      image_title: " SnapLogic Academy Launch Event @ Bengaluru, India",
      title: "Who should attend SnapLogic Academy?",
      ul_p: <>
        <ul>
          <li>College students or recent graduates looking for career advancement and networking opportunities</li>
          <li>Current IT professionals who want to expand their skill set </li>
        </ul>
      </>,
      description: ` SnapLogic Academy is for career aspirants looking for new skills in these uncertain times of layoffs, AI-triggered job cuts and the era of low-code platforms. The SnapLogic Academy will offer a comprehensive curriculum designed to upskill individuals and help them learn the latest tools and technologies. This move will help aspirants to be better equipped to take on the challenges of the digital world and stand out in the job market and command higher compensation packages.
      For global enterprise clients whose back-office operations are typically based out of India, the launch of SnapLogic Academy is a win as it ensures that their workforce has access to world-class training, to improve efficiency and productivity. `
    },
    sixth_section: {
      title: "Launch Event Highlights",
      image_01: images.TPA_evenntblog_01_group01_img01,
      image_01_title: "Evolution of Software Engineering Jobs",
      image_02: images.TPA_evenntblog_01_group01_img02,
      image_02_title: "Preparing for a stellar IT Career: 2023-2032",
      first_section_title: "Future of IT",

      image_03: images.TPA_evenntblog_01_group01_img03,
      sec_section_title: "Panel Discussions",

      image_04: images.TPA_evenntblog_01_group01_img04,
      image_05: images.TPA_evenntblog_01_group01_img05,
      third_section_title: "Engagement Activities"
    },
    seventh_section: {
      description_p1: "The SnapLogic Academy launch event was held in six cities across India on 6th May, 2023, was a huge success, with over 220 attendees and 45 Industry Leaders as speakers. ",
      description_p2: "The SnapLogic Academy launch event was held in six cities across India on 6th May, 2023, was a huge success, with over 220 attendees and 45 Industry Leaders as speakers. ",
      description_p3: "The event, which was held in top 5-star hotels across the country, was divided into five sessions. The first session focused on the future of software jobs in the AI and no-code era, and was led by CHROs and HR leaders in leading tech companies of India. The second session was all about preparing for a stellar IT career in the next decade, and featured talks by engineering leaders from some of the top IT and tech companies. ",
      description_p4: "Attendees had the opportunity to interact with each other and the speakers. Post Lunch, the event resumed with the SnapLogic USA team presenting an overview of SnapLogic iPaaS. The fourth session was a panel discussion on data integration and ETL featuring top industry leaders in the space. Finally, the event culminated in the launch of free learning courses on SnapLogic skills by The Platform Academy.",
      description_p5: "One of the most exciting aspects of the event was the fact that attendees were able to sign up for the free learning courses on the spot. The response was overwhelming, with many attendees expressing their excitement for the opportunity to learn SnapLogic skills and further their IT careers. In fact, many attendees even came prepared with their resumes, hoping to take advantage of the on-spot job interview opportunities that were available.  ",
      description_p6: "The success of the event has led SnapLogic Academy India to plan similar seminars once a month, in order to continue providing valuable learning opportunities to IT professionals across the country. Additionally, the free learning course access has been extended to people signing up for SnapLogic courses online as well. ",
      description_p7: "In conclusion, the launch of SnapLogic Academy in India is a significant move that is set to benefit global enterprise clients, large IT services giants, and career aspirants. The move will upskill the workforce and create a community of practicing SnapLogic platform technologists in India. With the growing demand for low-code platforms, the SnapLogic Academy is set to play a vital role in shaping the future of integration and helping individuals to succeed in the digital world. ",
    },
    Photo_Gallery: {
      image_01: images.TPA_evenntblog_01_group02_img01,
      image_02: images.TPA_evenntblog_01_group02_img02,
      image_03: images.TPA_evenntblog_01_group02_img03,
      image_04: images.TPA_evenntblog_01_group02_img04,
      image_05: images.TPA_evenntblog_01_group02_img05,
      image_06: images.TPA_evenntblog_01_group02_img06,
      image_07: images.TPA_evenntblog_01_group02_img07,
      image_08: images.TPA_evenntblog_01_group02_img08,
      image_09: images.TPA_evenntblog_01_group02_img09
    },
    Applauding_Comments: [
      {
        image: images.TPA_evenntblog_01_sankar,
        name: "Sankar Subramaniam",
        intro: " Head of Software Eng. & Product Development Broadcom Software",
        comment: "“Well Organised, extremely smooth in the entire process. I enjoyed meeting the other panelist, speakers and event organisers. Most importantly the event organisers did a good of getting the right crowd and making it a full house.”"
      },
      {
        image: images.TPA_evenntblog_01_neeraj,
        name: "Neeraj H Jahagirdar  ",
        intro: " Solutions Architect, Zycus",
        comment: "“The intent of the event was to the point and was the need of the hour. it was quite informative and the engagement post the event assured that the people who walked in, actually were benefitted with.”   "
      },
      {
        image: images.TPA_evenntblog_01_veenuA,
        name: "Venu A  ",
        intro: " Executive Director, PiOne Technologies",
        comment: "“Sincere appreciations for the support and hospitality extended by the team. I am happy to contribute to this initiative in in the future as well.” "
      }
    ]
  }
];



export {
  Benefits,
  WhyIndia,
  Learning,
  FeatureList,
  CityList,
  TopSnaplogicCourses,
  TopMobiusCourses,
  AllSnaplogicCourses,
  AllMobiusCourses,
  SnaplogicCourseDetails,
  EventList,
  TPALaunchEvent,
  SlEduSeries,
  DataEngineeringJobFair,
  DataScienceJobFair,
  BoostYourBusiness,
  StudentsFutureReady,
  BoostYourCareer,
  CustomCourse,
  EventBlogList,
  EducationalBlogList,
  SLAcademyLaunchBlogDescription
};
