import React from 'react';
import { NavBar } from '../container';
import { CourseRegistration } from '../container';



const Registration = () => {
    return(
        <>
            <NavBar />
            <CourseRegistration />
        </>
    );
};

export default Registration;