import axios from "axios";
import { useContext, useState } from "react";
import { toast } from "react-hot-toast";
import "./login.css"
import { GlobalContext } from "../../constants/Context";
import { Link, useNavigate } from "react-router-dom";
import bcrypt from 'bcryptjs';


export default function Login() {


  const { global_state, global_action, onLogin } = useContext(GlobalContext)

  const navigate = useNavigate()
  const [password, setPassword] = useState("")

  const [userDetails, setUserDetails] = useState({
    email: "",
  })

  const handleInput = (e) => {


    const { name, value } = e.target


    setUserDetails({ ...userDetails, [name]: value })


  }


  const login = async (e) => {
    e.preventDefault()
    // Object.entries(userDetails).forEach(([key, value]) => {
    //   console.log(`${key}: ${value}`);
    // });

    try {
      const res = await axios.post(`https://ingress-gateway.gaiansolutions.com/tf-web/v1.0/${process.env.REACT_APP_TENANTID}/schemas/${process.env.REACT_APP_SCHEMAID}/instances/list`, userDetails)

      if (res?.data?.msg == "entities not found for given criteria") {
        toast.error("Invalid credentials")
      }
      // console.log(res?.data?.msg)
      else {
        const payload = {
          email: userDetails.email
        }
        bcrypt.compare(password, res.data?.entities[0].password1)
          .then(async (isMatch) => {
            if (isMatch) {
              // Passwords match
              //const res = await axios.post(`https://ingress-gateway.gaiansolutions.com/tf-web/v1.0/${process.env.REACT_APP_TENANTID}/schemas/${process.env.REACT_APP_SCHEMAID}/instances/list?props=first_name%2Cemail%2Cselected_course%2Clast_name`, payload)
              // console.log(res.data?.entities[0]);
              const userData = {
                loginStatus: true,
                userid: res.data?.entities[0].id,
                username: `${res.data?.entities[0].first_name + " " + res.data?.entities[0].last_name}`,
                email: res.data?.entities[0].email,
                registeredCourses: res.data?.entities[0].selected_course,
                PhoneNumber: res.data?.entities[0].PhoneNumber,
              }

              global_action(userData)

              sessionStorage.setItem("id", JSON.stringify(userData))

              toast.success("Logging In")

              navigate("/", { replace: true })

              localStorage.setItem('token', userData);

              // console.log("matched")
              onLogin();
            } else {
              // Passwords don't match
              // console.log("not matched")
              toast.error("Incorrect Password")
            }
          })
      }
    } catch (err) {
      toast("Please enter valid credentials")
    }
  }


  return (
    <div className="formcontainer">

      <form>
        <small className="small">Sign in with your existing account.</small>
        <div>
          <label htmlFor="email">Email</label>
          <input placeholder="Email" onChange={(e) => { handleInput(e) }} name="email" type="email" />
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input placeholder="Password" onChange={(e) => { setPassword(e.target.value) }} name="Password" type="password" />
        </div>
        <div>
          <Link to='/resetpassword'>
            <span className="" >Forgot Password?</span>
          </Link>
        </div>
        <section className="bttnContainer">
          <button onClick={(e) => { login(e) }} type="submit">Sign In</button>
        </section>
      </form>

    </div>
  )
}