import React from 'react';
import './Partner.css';
import { images } from '../../constants';
import { Link } from 'react-router-dom';

const Snaplogic = ( {type} ) => {
    return(
        <>
            <div className="app__company app__snaplogic section__padding">

                <div className="company__monogram_top">
                    <img src={images.SnaplogicMonogram_UR} alt="SnapLogic Monogram" />
                </div>
                <div className="company__monogram_bottom">
                    <img src={images.SnaplogicMonogram_LL} alt="SnapLogic Monogram" />
                </div>

                {
                    type === "CoursesOffered" &&

                    <p className="app__company-heading p__subheading">
                        Offered by
                    </p>
                }

                <div className="app__company-logo snaplogic_logo">
                    <img src={images.SnaplogicLogo} alt="Snaplogic Logo" />
                </div>

                <p className="app__company-description p__paragraph">
                    Snaplogic is a powerful platform for data integration, API management, and automation. It allows users to connect data from various sources, such as databases, applications, and files, and automate workflows for faster and more efficient data processing.
                    <br></br>
                    <br></br>
                    SnapLogic also includes advanced features such as data transformation, data quality, and data governance, which enable users to clean, enrich, and secure their data as it flows through the integration pipeline. The platform also provides real-time monitoring and alerting capabilities to ensure that data is flowing smoothly and any issues are addressed promptly.
                </p>

                {
                    type === "CoursesOffered" ?

                    <Link to="/snaplogic" className="app__company-button large__button flex__center">
                        <button className='flex__center' type='button'>View Other Courses</button>
                    </Link>
                    :
                    <Link to="https://learn.snaplogic.com/" target="_blank" className="app__company-button flex__center">
                        <button className='flex__center' type='button'>Visit Website</button>
                    </Link>
                }
                

            </div>
        </>
    );
};

export default Snaplogic;