import React from 'react';
import { NavBar, Footer } from '../../container';
import { CourseDetails } from '../../container';
import { SnaplogicCourseDetails } from '../../constants';

const SnaplogicIntegratorCertification = () => {
    return(
        <>
            <NavBar />
            <CourseDetails title={SnaplogicCourseDetails[1].title} illustration={SnaplogicCourseDetails[1].illustration} description={SnaplogicCourseDetails[1].description} about={SnaplogicCourseDetails[1].about} curriculum={SnaplogicCourseDetails[1].curriculum} certification={SnaplogicCourseDetails[1].certification} />
            <Footer />
        </>
    );
};

export default SnaplogicIntegratorCertification;