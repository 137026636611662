import React from 'react';
import './Courses.css';
import { AllSnaplogicCourses, AllMobiusCourses } from '../../constants';
import { SnaplogicCourseCard, MobiusCourseCard } from '../../components';

const AllCourses = () => {
    
    return(
        <>
            <div className="app__allcourses section__padding">

                <h3 className="app__allcourses-heading p__heading">
                    All Courses
                </h3>

                <p className="app__allcourses-subheading p__subheading">
                    SnapLogic
                </p>

                <div className="app__allcourses-coursecontainer">
                    {
                        AllSnaplogicCourses.map( (course, idx) => <SnaplogicCourseCard title={course.title} illustration={course.illustration} navigatinglink={course.navigatinglink} key={"Course " + course.title + idx} /> )
                    }
                </div>

                <p className="app__allcourses-subheading p__subheading">
                    Mobius
                </p>

                <div className="app__allcourses-coursecontainer">
                    {
                        AllMobiusCourses.map( (course, idx) => <MobiusCourseCard title={course.title} illustration={course.illustration} navigatinglink={course.navigatinglink} key={"Course " + course.title + idx} /> )
                    }
                </div>

            </div>
        </>
    );
};

export default AllCourses;
