import React from 'react';
import './Partner.css';
import { images } from '../../constants';
import { Link } from 'react-router-dom';

const Mobius = ( {type} ) => {
    return(
        <>
            <div className="app__company app__mobius section__padding">

                <div className="company__monogram_top mobius__monogram_top">
                    <img src={images.MobiusMonogram_UR} alt="Mobius Monogram" />
                </div>
                <div className="company__monogram_bottom mobius__monogram_bottom">
                    <img src={images.MobiusMonogram__LL} alt="Mobius Monogram" />
                </div>

                {
                    type === "CoursesOffered" &&

                    <p className="app__company-heading p__subheading">
                        Offered by
                    </p>
                }

                <div className="app__company-logo mobius_logo">
                    <img src={images.MobiusLogo} alt="Mobius Logo" />
                </div>

                <p className="app__company-description p__paragraph">
                    Mobius DTaaS offers a suite of five SaaS tools designed to accelerate digital transformation and make it more accessible for businesses of all sizes. The Mobius DTaaS suite includes Pascal Intelligence (PI), BoltzmannBot (BoB), Monet, Vinci, and HolaVerse for low-code app development, customer interaction automation, digital marketing optimization, custom marketplace creation, and omnichannel engagement enhancement.
                </p>

                {
                    type === "CoursesOffered" ?

                    <Link to="/mobius" className="app__company-button large__button flex__center">
                        <button className='flex__center' type='button'>View Other Courses</button>
                    </Link>
                    :
                    <Link to="/under-construction" className="app__company-button flex__center">
                        <button className='flex__center' type='button'>Visit Website</button>
                    </Link>
                }
                

            </div>
        </>
    );
};

export default Mobius;