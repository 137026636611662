import React, { useContext, useEffect, useRef, useState } from 'react';
import './ContactUs.css';
import { images } from '../../constants';
import { MdEmail } from 'react-icons/md';
import { MdLocationPin } from 'react-icons/md';
import { RiCloseCircleFill } from 'react-icons/ri';
import emailjs from 'emailjs-com';
import { GlobalContext } from '../../constants/Context';
import axios from 'axios';
import { toast } from 'react-hot-toast';



const ContactUs = () => {


    const { global_state: { contactusPopup }, global_action } = useContext(GlobalContext);


    let temp = JSON.parse(sessionStorage.getItem("id"))

    const temp2 = () => new Date().getFullYear().toString() + (new Date().getMonth() + 1).toString() + new Date().getDate().toString() + new Date().getHours().toString() + new Date().getMinutes().toString() + new Date().getSeconds().toString() + new Date().getTime().toString()
    const parts = temp?.username.split(' ');
    const form = useRef();


    const [userDetails, setUserDetails] = useState({
        user_lastname: temp?.loginStatus ? parts?.pop() : "",
        user_firstname: temp?.loginStatus ? parts?.join(' ') : "",
        user_email: temp?.loginStatus ? temp?.email : "",
        user_phone: temp?.loginStatus ? temp?.PhoneNumber : "",
        user_coursecategory: "",
        user_message: "",
        unique_id: "",
    });

    const [initialState, setInitialState] = useState({ ...userDetails });

    const HandleUserDetails = (updatedDetails) => {
        setUserDetails({ ...userDetails, ...updatedDetails })
    };


    useEffect(() => {
        // Timer ID to keep track of the setTimeout call
        let timerId = null;
        
        // Debounced function to set the state after a certain time
        const debounceSetState = () => {
          clearTimeout(timerId);
          timerId = setTimeout(() => {
            // Set the state with the desired value
            setUserDetails({...userDetails,unique_id:temp2()})
          }, 200); // Debounce time of 500 milliseconds
        };
        
        // Call the debounced function whenever the value changes
        debounceSetState();
        
        // Clean up the timer on component unmount
        return () => clearTimeout(timerId);
      }, [userDetails.user_coursecategory]);



    const SendEmail = async (e) => {
        e.preventDefault();
        if(userDetails.user_coursecategory && userDetails.user_firstname && userDetails.user_email  && userDetails.user_phone){
            console.log()
            // Object.entries(userDetails).forEach(([key, value]) => {

            //     console.log(`${key}: ${value}`);
            // });
                try {
                    const res = await axios.post(`https://ingress-gateway.gaiansolutions.com/tf-web/v1.0/${process.env.REACT_APP_TENANTID}/schemas/${process.env.REACT_APP_CONTACTUS_SCHEMAID}/instance`, userDetails)
                }catch(error){
                    console.log(error)
                }

                emailjs.init(process.env.REACT_APP_PUBLICKEY);

                emailjs.sendForm(process.env.REACT_APP_SERVICEID, process.env.REACT_APP_TEMPLATEID, form.current, process.env.REACT_APP_PUBLICKEY)
                    .then((result) => {
                        console.log(result.text);
                    }, (error) => {
                        console.log(error.text);
                    });

        }
        else{
            toast.error("Message Not Sent! Please Fill All Mandatory Fields");
        }

    };

    return (
        <>
            <div className="app__contactus-container flex__center" style={contactusPopup ? { transition: '0.3s' } : { display: 'none', transition: '0.3s' }}>
                <div className="app__contactus flex__center">

                    <div className="app__contactus_info">
                        <div className="app__contactus_info-illustration">
                            <img src={images.ContactIllustration} alt="Illustration" />
                        </div>
                        <h1 className="app__contactus_info-heading flex__center p__heading">
                            Contact Information
                        </h1>
                        <div className="app__contactus_info-details flex__center">
                            <div className="app__contactus_info-details-item flex__center">
                                <MdEmail className='contactus__icons' />
                                <p className="p__paragraph">slacademy@tpaedu.org</p>
                            </div>
                            <div className="app__contactus_info-details-item flex__center">
                                <MdLocationPin className='contactus__icons' />
                                <p className="p__paragraph">5th Floor, Navayuga Vizva, Gachibowli - Miyapur Rd, Gachibowli, Telangana 500032</p>
                            </div>
                        </div>
                    </div>

                    <div className="app__contactus_form-container">
                        <form className="app__contactus_form" ref={form}>
                            <div className="app__contactus_form-row flex__center">
                                <div className="app__contactus_form-row-item flex__center">
                                    <label htmlFor='firstname' className="p__paragraph">First Name <small style={{ "color": "red" }}>*</small></label>
                                    <input id='firstname' type="text" name='user_firstname' className='app__contactus_form-input' value={userDetails.user_firstname} onChange={(e) => HandleUserDetails({ user_firstname: e.target.value })} required />
                                </div>
                                <div className="app__contactus_form-row-item flex__center">
                                    <label htmlFor='lastname' className="p__paragraph">Last Name</label>
                                    <input type="text" name='user_lastname' className='app__contactus_form-input' value={userDetails.user_lastname} onChange={(e) => HandleUserDetails({ user_lastname: e.target.value })} required />
                                </div>
                            </div>
                            <div className="app__contactus_form-row flex__center">
                                <div className="app__contactus_form-row-item flex__center">
                                    <label htmlFor='email' className="p__paragraph">E-mail <small style={{ "color": "red" }}>*</small></label>
                                    <input id='email' type="user_email" name='user_email' className='app__contactus_form-input' value={userDetails.user_email} onChange={(e) => HandleUserDetails({ user_email: e.target.value })} required />
                                </div>
                                <div className="app__contactus_form-row-item flex__center">
                                    <label htmlFor='user_phone' className="p__paragraph">Phone Number <small style={{ "color": "red" }}>*</small></label>
                                    <input id='phone' type="phone" name='user_phone' className='app__contactus_form-input' value={userDetails.user_phone} onChange={(e) => HandleUserDetails({ user_phone: e.target.value })} required />
                                </div>
                            </div>
                            <p className="app__contactus_form-subheading p__paragraph">
                                From?<small style={{ "color": "red" }}>*</small>
                            </p>
                            <div className='app__contactus_form-selector flex__center'>

                                <label className='p__paragraph flex__center'>
                                    <input type="radio" name='user_coursecategory' value="Learner" className='p__paragraph' onChange={(e) => HandleUserDetails({ user_coursecategory: e.target.value })} checked={userDetails.user_coursecategory === 'Learner'} />
                                    &nbsp; Learner
                                </label>
                                <label className='p__paragraph flex__center'>
                                    <input type="radio" name='user_coursecategory' value="Social Integrator" className='p__paragraph' onChange={(e) => HandleUserDetails({ user_coursecategory: e.target.value })} checked={userDetails.user_coursecategory === 'Social Integrator'} />
                                    &nbsp; Social Integrator
                                </label>
                                <label className='p__paragraph flex__center'>
                                    <input type="radio" name='user_coursecategory' value="Educational Institutes" className='p__paragraph' onChange={(e) => HandleUserDetails({ user_coursecategory: e.target.value })} checked={userDetails.user_coursecategory === 'Educational Institutes'} />
                                    &nbsp; Educational Institutes
                                </label>
                            </div>
                            <div className="app__contactus_form-message flex__center">
                                <label htmlFor='message' className="p__paragraph">Message</label>
                                <input id='message' type="text" name='user_message' placeholder='Write your message...' className='app__contactus_form-message-input' value={userDetails.user_message} onChange={(e) => HandleUserDetails({ user_message: e.target.value })} />
                            </div>

                        </form>

                        <div className="app__contactus_form-button flex__center" onClick={() => { global_action({ contactusPopup: false }); setUserDetails({ ...initialState }); }}>
                            <button type='button' onClick={(e) => SendEmail(e)} >
                                Send Message
                            </button>
                        </div>
                    </div>

                    <div className="app__contactus_close flex__center p__large" onClick={() => { global_action({ contactusPopup: false }); setUserDetails({ ...initialState }); }}>
                        <RiCloseCircleFill className='contactus_close' />
                    </div>

                </div>
            </div>
        </>
    );
};

export default ContactUs;
