import React from 'react';
import { UnderConstruction } from '../container';
import { Footer } from '../container';



const Construction = () => {
    const constructionStyle = {
        width: '100%',
        minHeight: '100vh',
        backgroundColor: 'var(--color-white)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
    };

    return(
        <div style={ constructionStyle }>
            <UnderConstruction />
            <Footer />
        </div>
    );
};

export default Construction;