import React from 'react';
import './CourseCard.css';
import { images } from '../../constants';
import { Link } from 'react-router-dom';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';

const MobiusCourseCard = ( {title, illustration, navigatinglink} ) => {
    return(
        <>
            <Link to={navigatinglink} className="app__coursecard-container">
                <div className="app__coursecard">

                    <div className="app__coursecard-illustration mobius__illustration">
                        <img src={illustration} alt={title + " Illustration"} />
                    </div>

                    <p className="app__coursecard-title p__paragraph">
                        {title}
                    </p>

                    <div className="app__coursecard-footer flex__center">
                        <div className="app__coursecard-footer_logo-container flex__center">
                            <div className="app__coursecard-footer_logo mobius__monogram flex__center">
                                <img src={images.MobiusMonogram} alt="Mobius Monogram" />
                            </div>
                            <p className="app__coursecard-footer_name flex__center">
                                MOBIUS
                            </p>
                        </div>
                        {
                            window.innerWidth > 750
                            ?
                            <Link to={navigatinglink} className="app__learningcard_navigate flex__center p__paragraph">
                                View Details <MdOutlineKeyboardArrowRight className='dropdown_arrow' />
                            </Link>
                            :
                            <Link to={navigatinglink} className="app__learningcard_navigate flex__center p__paragraph">
                                Details <MdOutlineKeyboardArrowRight className='dropdown_arrow' />
                            </Link>
                        }
                    </div>

                </div>
            </Link>
        </>
    );
};

export default MobiusCourseCard;
