import { NavBar, BlogList, Footer } from '../container';

const Blogs = () => {
    return (
        <>
            <NavBar />
            <BlogList />
            <Footer />
        </>
    )
}

export default Blogs;