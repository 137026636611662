import React, { useState } from "react";
import "./CourseRegistration.css";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";



export default function CourseRegistration() {

  const navigate = useNavigate();

  const [err, setErr] = useState(false);

  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    company: "",
    selected_course: "",
    years_of_experience: "",
    phone_number: "",
    current_designation: "",
    location: ""
  });

  //for input details state
  const handleInput = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  }

  //for form submission
  const register = async (e) => {

    e.preventDefault();

    setErr(true)

    if (!(userDetails.email && userDetails.name && userDetails.phone_number && userDetails.location && userDetails.company && userDetails.current_designation && userDetails.years_of_experience && userDetails.selected_course)) {
      toast.error("Please Enter All Fields");
      return;
    }

    // console.log(userDetails);

    try {
      const res = await axios.post(`https://ingress-gateway.gaiansolutions.com/tf-web/v1.0/${process.env.REACT_APP_TENANTID}/schemas/${process.env.REACT_APP_COURSEREGISTRATION_SCHEMAID}/instance?upsert=true`, userDetails)

      if (res.data) {
        let data = JSON.stringify({
          "user": {
            "email": userDetails.email,
            "name": userDetails.name
          }
        });

        // let config = {
        //   method: 'post',
        //   maxBodyLength: Infinity,
        //   url: 'https://ingress-gateway.gaiansolutions.com/utility-service/v1.0/create/user',
        //   headers: {
        //     'Content-Type': 'application/json'
        //   },
        //   data: data
        // };

        // axios.request(config)
        //   .then((response) => {
        //     toast.success("Course registration successful")
        //     // toast.success("Please set password on the next page")
        //     // window.open(response?.data?.invite_url)
        //     navigate("/", { replace: true })

        //   })
        //   .catch((error) => {
        //     toast.error("Something went wrong")
        //   });

        toast.success("Course registration successful");
        navigate("/", { replace: true });
      }
    } catch (err) {
      toast.error(`Something went wrong`)
    }
  };

  return (
    <div className="formContainer">
      <h1 className="form__heading p__subheading">
        Course Registration
      </h1>

      <form>

        <div className="nameContainer">

          <div className="name">
            <label htmlFor="email">Email<small style={{ "color": "red" }}>*</small></label>
            <input placeholder="Email" type="email" className={!userDetails?.email.length && err ? "red" : ""} value={userDetails?.email} onChange={(e) => { handleInput(e) }} name="email" />
          </div>

          <div className="name">
            <label htmlFor="name">Name<small style={{ "color": "red" }}>*</small></label>
            <input placeholder="Name" type="text" className={!userDetails?.name.length && err ? "red" : ""} value={userDetails?.name} onChange={(e) => { handleInput(e) }} name="name" />
          </div>

        </div>

        <div className="otherInfo">

          <div>
            <label htmlFor="PhoneNumber">Contact Number<small style={{ "color": "red" }}>*</small></label>
            <input placeholder="Contact Number" type="number" className={!userDetails?.phone_number.length && err ? "red" : ""} value={userDetails?.phone_number} onChange={(e) => { handleInput(e) }} name="phone_number" />
          </div>

          <div>
            <label htmlFor="Location">Current Location<small style={{ "color": "red" }}>*</small></label>
            <input placeholder="Location" type="text" value={userDetails?.location} onChange={(e) => { handleInput(e) }} name="location" />
          </div>

          <div>
            <label htmlFor="company">Organization/Educational Institution<small style={{ "color": "red" }}>*</small></label>
            <input placeholder="Organization / Educational Institution" type="text" value={userDetails?.company} onChange={(e) => { handleInput(e) }} name="company" />
          </div>

          <div>
            <label htmlFor="current_designation">Current Designation/Fresher<small style={{ "color": "red" }}>*</small></label>
            <input placeholder="Current Designation / Fresher" type="text" value={userDetails?.current_designation} onChange={(e) => { handleInput(e) }} name="current_designation" />
          </div>

          <div>

            <label htmlFor="Year_of_experience">Total years of experience<small style={{ "color": "red" }}>*</small></label>
            <input placeholder="Total years of experience" type="number" value={userDetails?.years_of_experience} onChange={(e) => { handleInput(e) }} name="years_of_experience" />
          </div>

          <div>
            <label htmlFor="courses">Which course are you interested in?<small style={{ "color": "red" }}>*</small></label>
            <select className={!userDetails?.selected_course.length && err ? "red1" : "select"} onChange={(e) => { handleInput(e) }} name="selected_course" >
              <option value="" disabled selected>Select an option</option>
              <option value="SL_dev_01">SnapLogic Certified Developer</option>
              <option value="SL_int_02">SnapLogic Certified Integrator</option>
              <option value="SL_arc_03">SnapLogic Certified Architect</option>
              <option value="SL_adm_04">SnapLogic Certified Administrator</option>
              <option value="SL_ent_05">SnapLogic Certified Enterprise Automation Professional</option>
              <option value="SL_acc_06">SnapLogic Accreditation</option>
            </select>
          </div>

        </div>

        <div className="btnContainer">
          <button onClick={(e) => { register(e) }} type="submit">Register</button>
        </div>

      </form>
    </div>
  )
};
