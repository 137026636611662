import React, { useContext, useRef, useState } from 'react';
import './CourseDetails.css';
import { FiShare2 } from 'react-icons/fi';
import { GrCertificate } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import Snaplogic from '../partner_info/Snaplogic';
import { GlobalContext } from '../../constants/Context';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';

const CourseDetails = ({ title, illustration, description, about, curriculum, certification }) => {

    const course_details_container = useRef(null);
    const course_details = useRef(null);
    const hero_container = useRef(null);

    const [fixedPosition, setFixedPosition] = useState({ smallDevice: true, largeDevice: false });

    const [indexing, setIndexing] = useState(false);

    window.onscroll = () => {
        // For Large Devices
        if (hero_container.current.getBoundingClientRect().bottom < 250)
            setFixedPosition((prevState) => { return { ...prevState, largeDevice: true } });
        else
            setFixedPosition((prevState) => { return { ...prevState, largeDevice: false } });

        // For Small Devices
        if (course_details_container.current.getBoundingClientRect().bottom < window.innerHeight)
            setFixedPosition((prevState) => { return { ...prevState, smallDevice: false } });
        else
            setFixedPosition((prevState) => { return { ...prevState, smallDevice: true } });

        // Z-Indexing of Buttons
        if (course_details.current.getBoundingClientRect().bottom < 0)
            setIndexing(true);
        else
            setIndexing(false);
    };

    const { global_state: { loginStatus } } = useContext(GlobalContext);

    // const title = 'Your Title';
    const text = `Check out ${title} on The Platform Academy`;
    const url = document.location.href;

    const handleCopy = () => {
        toast.success("Copied to clipboard")
    };

    // const ShareForSmallDevices = () => {
    //     let temp={
    //         title: `${title} | The Platform Academy`,
    //           text: `Check out ${title} on The Platform Academy`,
    //           url: document.location.href,
    //     }
    //     if (navigator.share) {
    //       navigator
    //         .share({
    //           temp
    //         })
    //         .then(() => {
    //           console.log('Successfully shared');
    //         })
    //         .catch(error => {
    //           console.error('Something went wrong while sharing the course: ', error);
    //         });
    //     }
    // };

    return (
        <div className='app__coursedetails-container' ref={course_details_container}>
            <div className="app__coursedetails section__padding" ref={course_details}>

                <h3 className="app__coursedetails_heading p__heading">
                    {title}
                </h3>

                <div className="app__coursedetails_hero flex__center" ref={hero_container}>

                    <div className="app__coursedetails_hero-illustration flex__center">
                        <img src={illustration} alt={title + " Illustration"} />
                    </div>

                    <div className="app__coursedetails_hero-details flex__center">
                        <div className='app__coursedetails_hero-details-info'>
                            <p className="app__coursedetails_hero-details-info-subheading p__subheading">
                                Description
                            </p>
                            <p className="app__coursedetails_hero-details-info-description p__paragraph">
                                {description}
                            </p>
                        </div>
                        {
                            window.innerWidth > 850 &&
                            <div className={fixedPosition.largeDevice ? "app__coursedetails_hero-details-navigation-fixed" : "app__coursedetails_hero-details-navigation"} style={indexing ? { zIndex: '-1' } : { zIndex: '1' }}>
                                <Link to="" className="app__coursedetails_hero-details-navigation-button">
                                    <CopyToClipboard text={`${url}`} onCopy={() => handleCopy()}>
                                        <button className='flex__center' type='button' onClick={() => handleCopy}><FiShare2 className='share_icon' /> Share</button>
                                    </CopyToClipboard>
                                </Link>
                                {
                                    loginStatus ?

                                        <Link to="https://accounts.skilljar.com/accounts/signup/?t=23kbjx5xu8vk9&d=1j0m3gk9de94&next=%2Fauth%2Fendpoint%2Flogin%2Fresult%3Fnext%3D%252F%26d%3D1j0m3gk9de94" target="_blank" className="app__coursedetails_hero-details-navigation-button">
                                            <button className='flex__center' type='button'>Enroll Now</button>
                                        </Link>
                                        :
                                        <Link to="/login" className="app__coursedetails_hero-details-navigation-button">
                                            <button className='flex__center' type='button'>Enroll Now</button>
                                        </Link>
                                }
                            </div>
                        }
                        {
                            window.innerWidth <= 850 &&
                            <div className="app__coursedetails_hero-details-navigation-small" style={fixedPosition.smallDevice ? { position: 'fixed' } : { position: 'absolute' }}>
                                <Link to="" className="app__coursedetails_hero-details-navigation-small-button">
                                    <CopyToClipboard text={`${text} \n using the url ${url}`} onCopy={() => handleCopy()}>
                                        <button className='flex__center' type='button'><FiShare2 className='share_icon' /></button>
                                    </CopyToClipboard>
                                </Link>
                                {
                                    loginStatus ?

                                        <Link to="https://accounts.skilljar.com/accounts/signup/?t=23kbjx5xu8vk9&d=1j0m3gk9de94&next=%2Fauth%2Fendpoint%2Flogin%2Fresult%3Fnext%3D%252F%26d%3D1j0m3gk9de94" target="_blank" className="app__coursedetails_hero-details-navigation-small-button flex__center">
                                            <button className='flex__center' type='button'><GrCertificate className='certificate_icon' /></button>
                                        </Link>
                                        :
                                        <Link to="/login" className="app__coursedetails_hero-details-navigation-small-button flex__center">
                                            <button className='flex__center' type='button'><GrCertificate className='certificate_icon' /></button>
                                        </Link>
                                }
                            </div>
                        }
                    </div>

                </div>

                <div className="app__coursedetails_content flex__center">

                    <div className={"app__coursedetails_content-about"}>
                        <p className="p__subheading">
                            About this course
                        </p>
                        <p className="p__paragraph">
                            {about}
                        </p>
                    </div>

                    <div className="app__coursedetails_content-curriculum-container">
                        <div className={"app__coursedetails_content-curriculum"}>
                            <p className="p__subheading">
                                Curriculum
                            </p>
                            <p className={"p__paragraph"}>
                                {curriculum}
                            </p>
                        </div>
                        <div className={"app__coursedetails_content-curriculum"}>
                            <p className="p__subheading">
                                Certification Exam
                                <br></br>
                                <span className='p__paragraph'>*Paid</span>
                            </p>
                            <p className={"p__paragraph"}>
                                {certification}
                                {
                                    loginStatus ?

                                        <Link to="https://accounts.skilljar.com/accounts/login/?t=23kbjx5xu8vk9&d=1j0m3gk9de94&next=%2Fauth%2Fendpoint%2Flogin%2Fresult%3Fnext%3D%252F%26d%3D1j0m3gk9de94" target="_blank" className="app__coursedetails_content-curriculum-button flex__center">
                                            <button type='button' className='p__paragraph'>Let's Go</button>
                                        </Link>
                                        :
                                        <Link to="/login" className="app__coursedetails_content-curriculum-button flex__center">
                                            <button type='button' className='p__paragraph'>Let's Go</button>
                                        </Link>
                                }
                            </p>
                        </div>
                    </div>

                </div>

            </div>

            <Snaplogic type={"CoursesOffered"} />
        </div>
    );
};

export default CourseDetails;