import React from 'react';
import './CommentCard.css'

const CommentCard = ({ image, name, intro, comment }) => {
    return (
        <>
            <div className="app_comment_card">

                <div className="app_comment_card_img">
                    <img src={image} />
                </div>

                <div className='app_comment_card_body'>
                    <div className="app_comment_card_name">
                        <p>{name}</p>
                    </div>
                    <div className="app_comment_card_intro">
                        <p>{intro}</p>
                    </div>
                </div>

                <div className="app_comment_card_comment">
                    <p>{comment}</p>
                </div>

            </div>
        </>
    );
};

export default CommentCard;
