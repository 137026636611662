import React, { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import "./login_signup.css"
import { Link } from "react-router-dom"

import { GlobalContext } from '../../constants/Context'

import Login from '../login/Login'
import NavBar from '../navbar/NavBar'
import SignUp from '../signUp/SignUp'

import tab from "../../assets/icon/tab-marker.png"


export default function Login_signup() {


  const location = useLocation();

  const { global_state, global_action } = useContext(GlobalContext)


  // console.log("here");




  return (
    <div>
      <NavBar />
      <div className='switch'>
        <Link to={"/login"}>
          <div className={location.pathname == "/login" ? "txt" : "blueTxt"}>SignIn</div>
        </Link>
        <Link to={"/signup"}>
          <div className={location.pathname == "/signup" ? "txt" : "blueTxt"}>SignUp</div>
        </Link>
      </div>

      <div className='tab'>
        <hr />
        <img className={location.pathname == "/login" ? "tabSignUp" : "tabSignIn"} src={tab} alt="" />
      </div>


      <div>

        {location.pathname == "/signup" && <SignUp />}

        {location.pathname == "/login" && <Login />}

      </div>
    </div>
  )
}
