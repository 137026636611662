import React from 'react';
import './Courses.css';
import { AllMobiusCourses } from '../../constants';
import { MobiusCourseCard } from '../../components';

const MobiusCourses = ( {type} ) => {
    
    return(
        <>
            <div className="app__allcourses section__padding">

                <h3 className="app__allcourses-heading p__heading">
                    {
                        type === "MobiusInfo" ? "Courses Offered" : "Mobius Courses"
                    }
                </h3>

                <div className="app__allcourses-coursecontainer">
                    {
                        AllMobiusCourses.map( (course, idx) => <MobiusCourseCard title={course.title} illustration={course.illustration} navigatinglink={course.navigatinglink} key={"Course " + course.title + idx} /> )
                    }
                </div>

            </div>
        </>
    );
};

export default MobiusCourses;
