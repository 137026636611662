import React from 'react';
import { NavBar, DataScienceEvent, Footer } from '../container';



const DataScienceJobHunt = () => {
    return(
        <>
            <NavBar />
            <DataScienceEvent />
            <Footer />
        </>
    );
};

export default DataScienceJobHunt;