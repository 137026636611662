import React, { useContext } from 'react';
import './MyCourses.css';
import { GlobalContext } from '../../constants/Context';
import { AllSnaplogicCourses } from '../../constants/data';
import { SnaplogicCourseCard, RegisteredCourseCard } from '../../components';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

const MyCourses = () => {

    const navigate = useNavigate()
    // const {global_state: {registeredCourses},} = useContext(GlobalContext);

    const temp = JSON.parse(sessionStorage.getItem("id"))
    if(temp==undefined) {
        navigate('/')
        // toast.error("please Login First")
        return
    }
    
    // Access the registeredCourses property
    const registeredCourses=temp?.registeredCourses
    // console.log(registeredCourses,"jjjj")

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1154, min: 650 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };
    
    return(
        <>
            <div className="app__mycourses section__padding">

                <h3 className="app__mycourses-heading p__heading">
                    My Courses
                </h3>
                <div className="app__mycourses-registeredcoursecontainer">
                    {
                        AllSnaplogicCourses.map( (course, idx) => {
                            if(registeredCourses.includes(course.courseId))
                                return <RegisteredCourseCard key={course.title + idx + " Course"} image={course.illustration} title={course.title} navigatingLink={course.navigatinglink} />
                        })
                    }
                </div>
                {
                    registeredCourses === "" &&
                    <p className="app__mycourses-registeredcoursemessage p__paragraph">You have not registered for any course</p>
                }

                <h3 className="app__mycourses-heading p__heading">
                    Explore more Courses
                </h3>
                <Carousel
                    className="app__mycourses-coursecontainer"
                    swipeable={true}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    centerMode={true}
                    slidesToSlide={1}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    customTransition="all 1s"
                    transitionDuration={1000}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={50}
                >
                    {
                        AllSnaplogicCourses.map( (course, idx) => {
                            if(!registeredCourses.includes(course.courseId))
                                return <SnaplogicCourseCard key={course.title + idx + " Course"} illustration={course.illustration} title={course.title} navigatinglink={course.navigatinglink} />
                        })
                    }
                </Carousel>

            </div>
        </>
    );
};

export default MyCourses;