import React, { useContext, useEffect, useRef } from 'react';
import './Events.css';
import './UpdatedCarousel.css';
import { EventList } from '../../constants/index.js';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../../constants/Context';

const EventDescription = ( {id, title, description, image} ) => {

    return(
        <>
            <div className="app__events-content flex__center">

                <div className="app__events-content_image">
                    <img src={image} alt={id + " image"} />
                </div>

                <div className="app__events-content_info">
                    <p className="app__event-content_info-title p__subheading">
                        {title}
                    </p>
                    <p className="app__events-content_info-details p__paragraph">
                        {description}
                    </p>
                </div>

            </div>
        </>
    );
};

const Events = () => {

    const eventSection = useRef(null);

    const {global_action} = useContext(GlobalContext);

    useEffect( () => {
        global_action( {eventSectionRef: eventSection} );
    }, []);

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 850 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 850, min: 650 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 650, min: 0 },
          items: 1
        }
    };
    
    return(
        <>
            <div className="app__events section__padding" id='events' ref={eventSection}>

                <h3 className="app__events-heading p__heading">
                    Our Events
                </h3>

                <Carousel
                    className="app__events-carousel"
                    swipeable={true}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    centerMode={false}
                    partialVisbile={false}
                    slidesToSlide={1}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    customTransition="all 1s"
                    transitionDuration={1000}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={50}
                >
                    {
                        EventList.map( (event, idx) => 
                        <>
                            <EventDescription key={event.id + idx + " Event"} id={event.id} title={event.title} description={event.description} image={event.image}/>
                            <Link to={event.navigatingLink} className="app__events-button">
                                <button className='flex__center' type='button'>View Event</button>
                            </Link>
                        </>
                        )
                    }
                </Carousel>

                

            </div>
        </>
    );
};

export default Events;