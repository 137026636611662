import React, { useState } from "react";
import './BlogList.css';
import { BlogCard } from '../../components';
import { EventBlogList, EducationalBlogList } from "../../constants";


const BlogList = () => {

    const [navigation, setNavigation] = useState(
        {
            eventBlogs: true,
            educationalBlogs: false
        }
    );

    return (
        <>
            <div className="app__blog section__padding">
                <div className="app__blog_subheading">
                    <span className={navigation.eventBlogs ? "app__blog_subheading-active p__paragraph" : "app__blog_subheading-default p__paragraph"} onClick={() => setNavigation({ eventBlogs: true, educationalBlogs: false })}>Event Blogs </span>
                    <span className={navigation.educationalBlogs ? "app__blog_subheading-active p__paragraph" : "app__blog_subheading-default p__paragraph"} onClick={() => setNavigation({ eventBlogs: false, educationalBlogs: true })}>Educational Blogs</span>
                </div>

                <div className="app__blog_content">
                    {
                        navigation.eventBlogs &&
                        <>
                            {
                                EventBlogList.length
                                    ?
                                    EventBlogList.map((blog, idx) => <BlogCard image={blog.images} description={blog.description} date={blog.date} id={blog.id} key={"Blog Card " + idx} />)
                                    :
                                    <>
                                        <p className="app__blog_content_noevent p__paragraph">
                                            No events have been held yet. Please stay tuned for upcoming events.
                                        </p>
                                    </>
                            }
                        </>
                    }
                    {
                        navigation.educationalBlogs &&
                        <>
                            {
                                EducationalBlogList.length
                                    ?
                                    EducationalBlogList.map((blog, idx) => <BlogCard image={blog.images} description={blog.description} date={blog.date} id={blog.id} key={"Blog Card " + idx} />)
                                    :
                                    <>
                                        <p className="app__blog_content_noevent p__paragraph">
                                            No events have been held yet. Please stay tuned for upcoming events.
                                        </p>
                                    </>
                            }
                        </>
                    }
                </div>

            </div>
        </>
    )
}

export default BlogList;
