import { useState } from "react"
import "./signUp.css"
import axios from "axios"

import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import bcrypt from 'bcryptjs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export default function SignUp() {


  const navigate = useNavigate()
  const [password1, setpassword] = useState("");
  const [password2, setpassword2] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [showPassword, setShowPassword] = useState({
    one: false,
    two: false
  });
  const round = 10;
  const [err, setErr] = useState(false)



  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password1: "",
    company: "",
    partner_id: "",
    selected_course: [],
    Year_of_experience: "",
    LinkedInProfile: "",
    PhoneNumber: "",
    Career_path: "",
    current_designation: ""
  })

  //for password show and hide 
  const toggleone = () => {
    setShowPassword({ ...showPassword, one: !showPassword.one })
  }

  const toggletwo = () => {
    setShowPassword({ ...showPassword, two: !showPassword.two })
  }

  //for password checking: matching credentials
  const handleBlur = (e) => {
    if (password1 && password2) {
      const newPassword = e.target.value;
      const isValid = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g.test(newPassword);
      if (!isValid) {
        setpasswordError("Passwords must follow mendataor criteria")
        toast.error("Password must meet the following criteria:At least 8 characters long,Contains at least one alphabetic character (a-z or A-Z),one numeric digit (0-9),one special character")
      }
      else {
        if (password1 != password2) {
          setpasswordError("Passwords must be same")
        }
        else {
          setpasswordError("")
        }
          if (password1 !== password2) {
            setpasswordError("Passwords must be same")
          }
          else {
            setpasswordError("")
          }
      }
    }
  };

  //for input details
  const handleInput = (e) => {
    const { name, value } = e.target
    // console.log(name, value)

    if (name === "password1") {
      bcrypt.hash(value, round)
        .then((hash) => {
          setUserDetails({ ...userDetails, [name]: hash });
        })
        .catch((err) => {
          console.log(err)
        });

    }
    else {
      setUserDetails({ ...userDetails, [name]: value });
    }



  }
 

  //for form Submit "Signup"
  const signUp = async (e) => {

    e.preventDefault()

    // Object.entries(userDetails).forEach(([key, value]) => {
    //   console.log(`${key}: ${value}`);
    // });

    setErr(true)


    if (!(userDetails.first_name && userDetails.last_name && userDetails.email && userDetails.password1 && userDetails.selected_course && userDetails.PhoneNumber)) {
      toast.error("Please Enter All Fields");
      return
    }

    // console.log(userDetails);

    try {
      const res = await axios.post(`https://ingress-gateway.gaiansolutions.com/tf-web/v1.0/${process.env.REACT_APP_TENANTID}/schemas/${process.env.REACT_APP_SCHEMAID}/instance?upsert=false`, userDetails)


      if (res.data) {

        let data = JSON.stringify({
          "user": {
            "email": userDetails.email,
            "first_name": userDetails.first_name,
            "last_name": userDetails.last_name
          }
        });

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://ingress-gateway.gaiansolutions.com/utility-service/v1.0/create/user',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };

        axios.request(config)
          .then((response) => {

            toast.success("Verification mail sent")
            toast.success("Please set password on the next page")
            window.open(response?.data?.invite_url)
            navigate("/", { replace: true })

          })
          .catch((error) => {
            toast.error("Something went wrong")
          });

      }
    } catch (err) {

      toast.error(`User with ${userDetails.email} already exists`)

    }


  }

  return (
    <div className="formContainer">
      <form>

        <div className="nameContainer">


          <div className="name">
            <label htmlFor="first_name">First Name<small style={{ "color": "red" }}>*</small></label>
            <input placeholder="First Name" type="text" className={!userDetails?.first_name.length && err ? "red" : ""} value={userDetails?.first_name} onChange={(e) => { handleInput(e) }} name="first_name" />
          </div>

          <div className="name">
            <label htmlFor="last_name">Last Name <small style={{ "color": "red" }}>*</small></label>
            <input placeholder="Last Name" type="text" className={!userDetails?.last_name.length && err ? "red" : ""} value={userDetails?.last_name} onChange={(e) => { handleInput(e) }} name="last_name" />

          </div>


        </div>

        <div className="otherInfo">

          <div>
            <label htmlFor="email">Email <small style={{ "color": "red" }}>*</small></label>
            <input placeholder="Email" type="email" className={!userDetails?.email.length && err ? "red" : ""} value={userDetails?.email} onChange={(e) => { handleInput(e) }} name="email" />
          </div>

          <div>
            <label htmlFor="PhoneNumber">Phone Number <small style={{ "color": "red" }}>*</small></label>
            <input placeholder="PhoneNumber" type="email" className={!userDetails?.PhoneNumber.length && err ? "red" : ""} value={userDetails?.PhoneNumber} onChange={(e) => { handleInput(e) }} name="PhoneNumber" />
          </div>

          <div>
            <label htmlFor="password1">Password <small style={{ "color": "red" }}>*</small></label>
            <div className="app_input_pass">
              <input placeholder="Password" type={showPassword.one ? 'text' : 'password'} className={!password1.length && err ? "red" : ""} value={password1} onChange={(e) => { handleInput(e); setpassword(e.target.value) }} onBlur={handleBlur} name="password1" />
              
                <FontAwesomeIcon
                     icon={showPassword.one ? faEyeSlash : faEye}
                     onClick={toggleone}
                     className="app_input_pass_eye"
                />
            </div>
          </div>

          <div>
            <label htmlFor="password2">Confirm Password <small style={{ "color": "red" }}>*</small></label>
            <div className="app_input_pass">
            <input placeholder="Password" type={showPassword.two ? 'text' : 'password'} className={!password2.length && err ? "red" : ""} value={password2} onChange={(e) => { setpassword2(e.target.value) }} onBlur={handleBlur} name="password2" />
            <FontAwesomeIcon
                     icon={showPassword.two ? faEyeSlash : faEye}
                     onClick={toggletwo}
                     className="app_input_pass_eye"
                />
            </div>
            
          </div>

          {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
          <div>
            <label htmlFor="LinkedInProfile">LinkedIn Profile </label>
            {/* <small>Optional</small> */}
            <input placeholder="LinkedIn Profile" type="text" value={userDetails?.LinkedInProfile} onChange={(e) => { handleInput(e) }} name="LinkedInProfile" />
          </div>

          <div>
            <label htmlFor="company">Company/Institution</label>
            {/* <small>Optional</small> */}
            <input placeholder="Company / Institution" type="text" value={userDetails?.company} onChange={(e) => { handleInput(e) }} name="company" />
          </div>

          <div>
            <label htmlFor="current_designation">Current Designation/Fresher</label>
            {/* <small>Optional</small> */}
            <input placeholder="Current Designation / Fresher" type="text" value={userDetails?.current_designation} onChange={(e) => { handleInput(e) }} name="current_designation" />
          </div>

          <div>

            <label htmlFor="Year_of_experience">Years of Working experience</label>
            <select className={!userDetails?.Year_of_experience.length && err ? "red1" : "select"} onChange={(e) => { handleInput(e) }} name="Year_of_experience" >
              <option value="" disabled selected>Select an option</option>
              <option value="0-2 years">0-2 years</option>
              <option value="2-5 years">2-5 years</option>
              <option value="5-8 years">5-8 years</option>
              <option value="8-12 years">8-12 years</option>
              <option value="12+ years">12+ years</option>
            </select>
          </div>

          <div>

            <label htmlFor="Career_path">career path</label>
            <select className={!userDetails?.Career_path.length && err ? "red1" : "select"} onChange={(e) => { handleInput(e) }} name="Career_path" value={userDetails?.Career_path} >
            <option value="" disabled selected>Select an option</option>
              <option value="Integrator">Integrator</option>
              <option value="Developer">Developer</option>
              <option value="Architect">Architect</option>
              <option value="Administrator">Administrator</option>
              <option value="career_counsellor's_advice">I want a career counsellor's advice</option>
            </select>
          </div>
          <div>

            <label htmlFor="courses">Select Course <small style={{ "color": "red" }}>*</small></label>
            <select className={!userDetails?.selected_course.length && err ? "red1" : "select"} onChange={(e) => { setUserDetails({ ...userDetails, selected_course: [...userDetails?.selected_course, e.target.value] }) }} name="course" >
              <option value="" disabled selected>Select an option</option>
              <option value="SL_dev_01">SnapLogic Certified Developer</option>
              <option value="SL_int_02">SnapLogic Certified Integrator</option>
              <option value="SL_arc_03">SnapLogic Certified Architect</option>
              <option value="SL_adm_04">SnapLogic Certified Administrator</option>
              <option value="SL_ent_05">SnapLogic Certified Enterprise Automation Professional</option>
              <option value="SL_acc_06">SnapLogic Accreditation</option>
            </select>
          </div>
          <div>
            <label htmlFor="partner_id">Partner ID </label>
            {/* <small>Optional</small> */}
            <input placeholder="Partner Id" type="text" value={userDetails?.partner_id} onChange={(e) => { handleInput(e) }} name="partner_id" />
          </div>

        </div>

        <div className="btnContainer">
          <button onClick={(e) => { signUp(e) }} type="submit">Sign Up</button>
        </div>
      </form>
    </div>

  )
}