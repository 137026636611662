import React, { useContext, useEffect } from 'react';
import { NavBar, MyCourses, Footer } from '../container';
import { toast } from "react-hot-toast"
import { GlobalContext } from '../constants/Context';
import { useNavigate } from 'react-router-dom';

const Dashboard = ({onLogout}) => {


    const { global_state } = useContext(GlobalContext)

    const navigate = useNavigate()
    console.log("here");

    useEffect(() => {
        if (!sessionStorage.getItem("id") && !global_state.loginStatus) {

            navigate("/login")

            toast.error("Please login")
        }
    }, [])

    return (
        <>
            <NavBar/>
            <MyCourses />
            <Footer />
        </>
    );
};

export default Dashboard;